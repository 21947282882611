<template>
    <div class="step px-3">
        <div class="row mb-3">
            <div class="col-12">
                <label class="form-label h5 fw-bold" for="campaign-list">{{ $tc('campaign', 1) }}</label>
                <component-searchable-dropdown vuexAction="CAMPAIGN/LIST"
                                               :vuexResultsState="campaigns"
                                               searchColumnName="gen_campaign_desc_short"
                                               :additional-filters="listCampaignFilters"
                                               @onItemSelect="onChangeTemporaryCampaign"
                                               searchValueName="campaignDescShort"/>
                <div v-if="!this.$v.temporaryCampaignUniqueId.required" class="invalid-feedback">
                    {{ $t('validation.error.required.campaign') }}
                </div>
            </div>
        </div>
        <form v-if="temporaryCampaignUniqueId" class="position-relative">
            <div class="row mt-3">
                <div class="col-12">
                    <label for="campaign-name" class="form-label">
                        Deutsche Post Print-Mailing Automation {{ $tc('campaign-name', 1) }}
                    </label>
                    <input id="campaign-name"
                           class="form-control"
                           disabled
                           type="text"
                           v-if="Object.keys(temporaryCampaign).length > 0"
                           v-model="temporaryCampaign.config.campaignData.campaignName">
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <h2 class="h5"><strong>{{ $tc('variable', 2) }}</strong></h2>
                    <div class="form-text">
                        {{ $t('pages.campaign-settings.variables-message') }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 mb-3">

                    <ul class="list-group mt-1 p-0">

                        <li class="list-group-item">
                            <div class="d-flex justify-content-between">
                                <strong style="flex: 1 0 10%">{{ $tc('content', 1) }}</strong>
                                <strong style="flex: 1 0 20%">{{ $t('modal.recipients-and-campaigns-configuration.dp-variable-name') }}</strong>
                                <strong style="flex: 1 0 20%">{{ $tc('type', 1) }}</strong>
                            </div>
                        </li>

                        <li v-for="(variable, i) in variables"
                            :key="'variable-new-' + i"
                            class="list-group-item">

                            <div class="d-flex justify-content-between">

                                <!-- FIELD -->
                                <div class="me-3" style="flex: 1 0 10%">
                                    <label :for="'campaign-variable-' + i + '-field'" class="sr-only">
                                        <strong>{{ $tc('field', 1) }}</strong>
                                    </label>
                                    <select :id="'campaign-variable-' + i + '-field'"
                                            v-model="variable.field" class="form-select"
                                            disabled>
                                        <option v-for="(key, value) in fields"
                                                :key="'variable-field-' + key"
                                                :value="value">
                                            {{ $t('variables.' + value) }}
                                        </option>
                                        <option :value="null">{{ $t('variables.OTHER') }}</option>
                                    </select>
                                </div>

                                <!-- VARIABLE NAME -->
                                <div class="form-group mb-0 me-3" style="flex: 1 0 20%">
                                    <label :for="'campaign-variable-' + i + '-name'" class="form-label sr-only">
                                        <strong>{{ $tc('variable-name', 1) }}</strong>
                                    </label>
                                    <input :id="'campaign-variable-' + i + '-name'"
                                           v-model="variable.name"
                                           :name="'campaign_variable[' + i + '][name]'"
                                           class="form-control w-100"
                                           disabled
                                           required="required"
                                           type="text">
                                </div>

                                <!-- VARIABLE TYPE -->
                                <div class="form-group mb-0 me-3 flex-grow-1" style="flex: 1 0 20%">
                                    <label :for="'campaign-variable-' + i + '-type'" class="form-label sr-only">
                                        <strong>{{ $tc('variable-type', 1) }}</strong>
                                    </label>
                                    <select :id="'campaign-variable-' + i + '-type'"
                                            v-model="variable.type"
                                            :name="'campaign_variable[' + i + '][type]'"
                                            class="form-select w-100"
                                            disabled
                                            required="required">
                                        <option v-for="(value, i) in variableType"
                                                :key="'variable-new-variable-type-' + i"
                                                :value="value">
                                            {{ value }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <component-loading v-if="loading.CAMPAIGN_GET"
                               background-color="rgba(255,255,255,.6)"/>
        </form>
        <div v-else class="col-12">
            <div class="alert alert-info d-flex align-items-center mb-4">
                <font-awesome-icon class="ms-2 me-3" icon="info" size="2x"/>
                {{ $t('modal.recipients-and-campaigns-configuration.select-campaign-description') }}
            </div>
        </div>
    </div>
</template>
<script>
import ComponentLoading from "../../../components/ui/ComponentLoading";
import ComponentSearchableDropdown from '../../ui/ComponentSearchableDropdown.vue';
import {required} from "vuelidate/lib/validators";

export default {
    name: "ComponentReuseVariableConfiguration",
    components: {ComponentLoading, ComponentSearchableDropdown},
    computed: {
        v() {
            return this.$v;
        },
        formValid() {
            return !this.$v.$invalid;
        },
        loading() {
            return {
                CSV: false,
                CONTACT_LIST: this.$store.state.loading.CONTACT_LIST,
                EVENT_TRIGGER_CAMPAIGN: this.$store.state.loading.EVENT_TRIGGER_CAMPAIGN,
                CAMPAIGN_GET: this.$store.state.loading.CAMPAIGN_GET,
                CAMPAIGN_LIST: this.$store.state.loading.CAMPAIGN_LIST,
            }
        },
        campaign() {
            return this.$store.state.CAMPAIGN.campaign;
        },
        campaigns() {
            return this.$store.state.CAMPAIGN.campaigns;
        },
        listCampaignFilters() {
            return {
                "genStatus.gen_status_desc_short": this.campaign.campaignType === 'CAMPAIGN_REGULAR' ? 'CAMPAIGN_COMPLETED' : 'CAMPAIGN_ACTIVE',
                'genType.gen_type_desc_short': this.campaign.campaignType
            }
        },
        temporaryCampaign() {
            return this.$store.state.CAMPAIGN.temporaryCampaign;
        },
        versioning() {
            if (this.configExists) {
                let version = this.campaign.config.versioning;
                version.variables = this.campaign.config.versioning.variables + 1;
                return version;
            } else {
                return {
                    variables: 2,
                    recipients: 1,
                    recipientMapping: 1
                }
            }
        },
        editingDisabled() {
            return !!(
                this.campaignVariablesConfigured
                && this.campaign.config.configSourceType === 'EXISTING_CAMPAIGN'
            );
        },
        variables() {
            let variables = [];

            if (Object.keys(this.temporaryCampaign).length > 0
                && Object.prototype.hasOwnProperty.call(this.temporaryCampaign, 'config')
                && Object.prototype.hasOwnProperty.call(this.temporaryCampaign.config, 'variables')
                && this.temporaryCampaign.config.variables.length > 0
            ) {
                variables = this.temporaryCampaign.config.variables;
            }

            return variables;
        }
    },
    props: {
        fields: {
            Type: Object,
            required: true
        },
        variableType: {
            Type: Object,
            required: true
        },
        configExists: {
            Type: Boolean,
            required: false
        },
        campaignVariablesConfigured: {
            Type: Boolean,
            required: false
        },
    },
    data() {
        return {
            temporaryCampaignUniqueId: ''
        }
    },
    methods: {
        async onChangeTemporaryCampaign(item) {
            if (!item) {
                this.temporaryCampaignUniqueId = null;
            }
            this.temporaryCampaignUniqueId = item.campaignUniqueId;
            await this.getCampaignTemporary(item.campaignUniqueId);
        },
        async getCampaignTemporary(campaignUniqueId) {
            await this.$store.dispatch('CAMPAIGN/GET_TEMPORARY', campaignUniqueId);
        },
        async saveConfiguration() {
            if (Object.keys(this.temporaryCampaign).length === 0) {
                await this.getCampaignTemporary(this.campaign.campaignUniqueId);
            }

            this.$store.dispatch('CAMPAIGN/UPDATE_CHANNEL', {
                campaignUniqueId: this.campaign.campaignUniqueId,
                channelUniqueId: this.campaign.outboundChannels[0].channelUniqueId,
                saveExternalCampaign: false,
                configData: {
                    versioning: this.versioning,
                    campaignData: {
                        campaignName: this.temporaryCampaign.config.campaignData.campaignName,
                        startDate: '2020-01-01',
                        endDate: null
                    },
                    printNodes: [
                        {
                            printNodeId: 'Print_Node_01',
                            description: 'Print_Node_01'
                        }
                    ],
                    variables: this.variables,
                    staticValues: this.campaign.config ? this.campaign.config.staticValues : null,
                    sourceColumns: this.campaign.config ? this.campaign.config.sourceColumns : null,
                    recipientMapping: this.campaign.config ? this.campaign.config.recipientMapping : null,
                    configSourceType: 'EXISTING_CAMPAIGN',
                    printMailingAutomationCampaignUniqueId: this.temporaryCampaign.config.printMailingAutomationCampaignUniqueId,
                }
            })
                .then(() => {
                    this.$store.dispatch('CAMPAIGN/GET', this.campaign.campaignUniqueId);
                })
                .catch(() => {
                    this.$store.dispatch('toast/danger', {
                        title: this.$tc('toast.danger.error'),
                        message: this.$tc('toast.danger.campaign-channel-updated')
                    });
                });
        },
        checkFormValidation() {
            this.$emit('validateForm', 'reusedCampaign', this.formValid)
        }
    },
    watch: {
        formValid() {
            this.checkFormValidation();
        }
    },
    validations: {
        temporaryCampaignUniqueId: {
            required
        },
    }
}
</script>
