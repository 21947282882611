<template>
    <div class="step px-3">
        <div class="row">
            <div class="col-12">
                <div class="alert alert-info d-flex align-items-center">
                    <font-awesome-icon class="me-3" icon="info-circle" size="2x"/>
                    {{ $t('alerts.info.variable-mapping-csv') }}
                </div>
            </div>
            <div v-if="!loading.CAMPAIGN_GET && campaign.config" class="col-12 mb-3">
                <div class="table-responsive">
                    <table class="table table-hover">
                        <thead>
                        <tr>
                            <th scope="col">
                                <strong class="flex-basis-33">{{ $tc('variable-name', 1) }}</strong>
                            </th>
                            <th scope="col">
                                <strong class="flex-basis-33">{{ $tc('csv-variable', 1) }}</strong>
                            </th>
                        </tr>
                        <tr v-for="(variable, i) in variables" :key="'variable-mapping-' + i">
                            <th class="align-middle">
                                <strong>{{ variable.name }}</strong>
                            </th>
                            <td>
                                <label :for="'csv-variable-' + i" class="form-label sr-only">
                                    <strong>{{ $tc('csv-variable', 1) }}</strong>
                                </label>
                                <select :id="'csv-variable-' + i"
                                        v-model="recipientMapping[variable.name]"
                                        :class="{ 'is-invalid': !recipientMapping[variable.name] }"
                                        class="form-select w-100"
                                        @change="$forceUpdate()">
                                    <option value="">&mdash; {{ $t('actions.please-select') }} &mdash;</option>
                                    <template v-if="CsvColumns">
                                        <option v-for="(value, j) in CsvColumns"
                                                :key="'upload-csv-' + i + '-' + j"
                                                :value="value">
                                            {{ value }}
                                        </option>
                                    </template>

                                    <template v-else>
                                        <option v-for="(value, j) in variableValues"
                                                :key="'upload-csv-' + i + '-' + j"
                                                :value="value">
                                            {{ value }}
                                        </option>
                                    </template>
                                </select>
                                <div class="invalid-feedback">{{ $t('validation.error.required.csv-mapping') }}</div>
                            </td>
                        </tr>
                        </thead>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ComponentImportVariableMapping",
    props: {
        csvVariables: {
            Type: String,
            required: false
        }
    },
    computed: {
        loading() {
            return {
                CSV: false,
                CONTACT_LIST: this.$store.state.loading.CONTACT_LIST,
                EVENT_TRIGGER_CAMPAIGN: this.$store.state.loading.EVENT_TRIGGER_CAMPAIGN,
                CAMPAIGN_GET: this.$store.state.loading.CAMPAIGN_GET,
                CAMPAIGN_LIST: this.$store.state.loading.CAMPAIGN_LIST,
            }
        },
        campaign() {
            return this.$store.state.CAMPAIGN.campaign;
        },
        processJob() {
            return this.$store.state.PROCESS_JOB.processJob
        },
        variables() {
            return this.$store.getters['CAMPAIGN/variables'];
        },
        recipientMapping() {
            return this.$store.getters["CAMPAIGN/recipientMapping"];
        },
        versioning() {
            let version = this.campaign.config.versioning;
            version.recipientMapping = this.campaign.config.versioning.recipientMapping + 1;
            return version;
        },
        inputValid() {
            let valid = true;

            if (Object.keys(this.recipientMapping).length > 0) {
                for (const field of Object.entries(this.recipientMapping)) {
                    if (!field[1]) {
                        valid = false;
                    }
                }
            }

            return valid;
        },
        CsvColumns() {
            let columns = this.csvVariables;

            if (!this.csvVariables && this.campaign.config && this.campaign.config.sourceColumns) {
                columns = this.campaign.config.sourceColumns;
            }

            return columns;
        }
    },
    data() {
        return {
            //mapping: {},
            variableValues: []
        }
    },
    methods: {
        initMapping() {
            this.variables.forEach(variable => this.recipientMapping[variable.name] = '')
        },
        initPreSavedMapping() {
            // eslint-disable-next-line no-unused-vars
            for (const [value, field] of Object.entries(this.recipientMapping)) {
                this.variableValues.push(field);
            }
        },
        saveConfiguration() {
            this.$store.dispatch('CAMPAIGN/UPDATE_CHANNEL', {
                campaignUniqueId: this.campaign.campaignUniqueId,
                channelUniqueId: this.campaign.outboundChannels[0].channelUniqueId,
                saveExternalCampaign: false,
                configData: {
                    versioning: this.versioning,
                    campaignData: {
                        campaignName: this.campaign.config ? this.campaign.config.campaignData.campaignName : null,
                        startDate: '2020-01-01',
                        endDate: null
                    },
                    printNodes: [
                        {
                            printNodeId: 'Print_Node_01',
                            description: 'Print_Node_01'
                        }
                    ],
                    variables: this.campaign.config ? this.campaign.config.variables : null,
                    staticValues: null,
                    sourceColumns: this.CsvColumns,
                    recipientMapping: this.recipientMapping,
                    configSourceType: this.campaign.config ? this.campaign.config.configSourceType : null,
                    printMailingAutomationCampaignUniqueId: this.campaign.config
                        ? this.campaign.config.printMailingAutomationCampaignUniqueId
                        : null,
                }
            })
                .catch(() => {
                    this.$store.dispatch('toast/danger', {
                        title: this.$tc('toast.danger.error'),
                        message: this.$tc('toast.danger.campaign-channel-updated')
                    });
                });
        },
        checkFormValidation() {
            this.$emit('validateForm', 'importVariableMapping', this.inputValid)
        }
    },
    watch: {
        inputValid() {
            this.checkFormValidation();
        }
    }
}
</script>
