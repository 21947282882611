<template>
    <div class="step px-3">
        <div class="row mb-3">
            <div class="col-12" v-if="rules.list.length > 0">
                <label class="form-label h5 fw-bold" for="rules">{{ $tc('rule', 2) }}</label>
                <select id="rules"
                        v-model="selectedRule"
                        :class="{ 'is-invalid': this.$v.selectedRule.$invalid }"
                        aria-label="Segment"
                        class="form-select">
                    <option disabled value="">&mdash; {{ $t('actions.please-select') }} &mdash;</option>
                    <option v-for="(rule, i) in rules.list" :key="'rule-' + rule.ruleUniqueId" :value="i">
                        {{ rule.ruleDescShort }}
                    </option>
                </select>
                <div v-if="!this.$v.selectedRule.required" class="invalid-feedback">
                    {{ $t('validation.error.required.rule') }}
                </div>
            </div>
            <div v-if="rules.list.length === 0" class="col-12">
                <p>{{ $t('modal.recipients-and-campaigns-configuration.no-rules-found') }}</p>
                <p>
                    <button class="btn btn-primary" type="button" @click="onClickCreateRule">
                        {{ $t('actions.create-rule') }}
                    </button>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import closeModal from "../../../lib/close-modal";
import {required} from "vuelidate/lib/validators";

export default {
    name: "ComponentRuleRecipients",
    computed: {
        v() {
            return this.$v;
        },
        formValid() {
            return !this.$v.$invalid;
        },
        loading() {
            return {
                CSV: false,
                CONTACT_LIST: this.$store.state.loading.CONTACT_LIST,
                EVENT_TRIGGER_CAMPAIGN: this.$store.state.loading.EVENT_TRIGGER_CAMPAIGN,
                CAMPAIGN_GET: this.$store.state.loading.CAMPAIGN_GET,
                CAMPAIGN_LIST: this.$store.state.loading.CAMPAIGN_LIST,
            }
        },
        rules() {
            return this.$store.state.RULE.rules;
        },
        campaign() {
            return this.$store.state.CAMPAIGN.campaign;
        },
        recipientConfig() {
            return this.$store.state.CAMPAIGN.recipientConfig;
        }
    },
    props: {
        recipientsConfigured: {
            Type: Boolean,
            required: true
        }
    },
    data() {
        return {
            selectedRule: ""
        }
    },
    methods: {
        listRules() {
            this.$store.dispatch('RULE/LIST', {
                filter: {},
                order: {meta_insert_dt: "DESC"},
                page: 1,
                itemsPerPage: null,
                limit: null,
            })
        },
        saveRecipientsConfig() {
            this.$store.dispatch('CAMPAIGN/SAVE_RECIPIENT_CONFIG', {
                campaignUniqueId: this.campaign.campaignUniqueId,
                sourceType: 'RULE',
                sourceName: this.rules.list[this.selectedRule].ruleDescShort,
                contactUniqueIds: [],
                segmentUniqueIds: [],
                ruleUniqueId: this.rules.list[this.selectedRule].ruleUniqueId,
                importConfig: {
                    mediaObjectUniqueId: null,
                    processJobUniqueId: null,
                    workflowUniqueId: null
                }
            })
                .then(() => {
                    this.$store.dispatch('CAMPAIGN/GET_RECIPIENT_CONFIG', this.campaign.campaignUniqueId);
                });
        },
        onClickCreateRule() {
            closeModal(this.$refs.recipientsAndCampaignConfiguration.$el);
            this.$router.push({name: 'automation-rules'});
        },
        checkFormValidation() {
            this.$emit('validateForm', 'ruleRecipients', this.formValid)
        }
    },
    watch: {
        formValid() {
            this.checkFormValidation();
        },
        rules() {
            if (this.recipientsConfigured) {
                if (this.recipientConfig.sourceType === 'RULE') {
                    this.rules.list.forEach((rule, i) => {
                        if (this.recipientConfig.ruleUniqueId === rule.ruleUniqueId) {
                            this.selectedRule = i;
                        }
                    })
                }
            }
        }
    },
    validations: {
        selectedRule: {
            required
        }
    },
    beforeMount() {
        if (this.recipientsConfigured) {
            if (this.recipientConfig.sourceType === 'RULE') {
                this.rules.list.forEach((rule, i) => {
                    if (this.recipientConfig.ruleUniqueId === rule.ruleUniqueId) {
                        this.selectedRule = i;
                    }
                })
            }
        }
    }
}
</script>
