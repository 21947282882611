<template>
    <div class="step px-3">
        <div v-if="importCompleted" class="row">
            <div class="col-12">
                <div class="alert alert-info d-flex align-items-center">
                    <font-awesome-icon class="me-3" icon="info-circle" size="2x"/>
                    {{ $t('modal.recipients-and-campaigns-configuration.contacts-in-file-count', {count: recipients.summary.total}) }}
                </div>
                <div class="table-responsive">
                    <table class="table table-sm table-bordered">
                        <thead>
                        <tr>
                            <th v-for="(column, i) in columnMapping" :key="'csv-data-heading-' + i" scope="col">
                                <strong class="me-1">{{ Object.keys(column).join() }}</strong>
                                <small class="badge bg-info">{{ column[Object.keys(column)] }}</small>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(recipient, i) in recipients.list" :key="'csv-data-body-' + i">
                            <td v-for="(column, j) in columnMapping" :key="'csv-data-body-cell-' + i + '-' + j">
                                {{ recipient[column[Object.keys(column).join()]] }}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div v-else class="row">
            <div class="col-12">
                <div class="alert alert-info d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center">
                        <font-awesome-icon class="me-3" icon="info-circle" size="2x"/>
                        {{ $t('modal.recipients-and-campaigns-configuration.file-import-started') }}
                    </div>
                    <button class="btn btn-primary" @click="onPreviewRecipient">{{ $t('actions.refresh') }}</button>
                </div>
            </div>
        </div>

        <component-loading v-if="loading.PROCESS_JOB_GET || loading.CAMPAIGN_PREVIEW_RECIPIENT"
                           background-color="rgba(255,255,255,.6)"/>
    </div>
</template>

<script>
import ComponentLoading from "../../../components/ui/ComponentLoading";

export default {
    name: "ComponentImportDataPreview",
    components: {ComponentLoading},
    props: {
        csvVariables: {
            Type: String,
            required: true
        },
        processJobUniqueId: {
            Type: String,
            required: true
        }
    },
    computed: {
        loading() {
            return {
                PROCESS_JOB_GET: this.$store.state.loading.PROCESS_JOB_GET,
                CAMPAIGN_PREVIEW_RECIPIENT: this.$store.state.loading.CAMPAIGN_PREVIEW_RECIPIENT,
            }
        },
        campaign() {
            return this.$store.state.CAMPAIGN.campaign;
        },
        processJob() {
            return this.$store.state.PROCESS_JOB.processJob
        },
        recipients() {
            return this.$store.state.CAMPAIGN.recipients
        }
    },
    data() {
        return {
            importCompleted: false,
            columnMapping: []
        }
    },
    methods: {
        initMapping() {
            this.campaign.config.recipientMapping.forEach(variable => this.mapping[variable.name] = '')
        },
        async onPreviewRecipient() {
            await this.$store.dispatch('PROCESS_JOB/GET', this.processJobUniqueId);
            if (this.processJob.processJobStatus === 'PROCESS_JOB_SUCCESS') {
                await this.$store.dispatch('CAMPAIGN/PREVIEW_RECIPIENT', {
                    campaignUniqueId: this.campaign.campaignUniqueId,
                    limit: 20
                })
                this.initColumnMapping();
                this.importCompleted = true;
            }
        },
        async previewSavedRecipients() {
            await this.$store.dispatch('CAMPAIGN/PREVIEW_RECIPIENT', {
                campaignUniqueId: this.campaign.campaignUniqueId,
                limit: 20
            })
            this.initColumnMapping();
            this.importCompleted = true;
        },
        checkFormValidation() {
            this.$emit('validateForm', 'importCompleted', this.importCompleted)
        },
        initColumnMapping() {
            this.columnMapping = [];
            Object.entries(this.campaign.config.recipientMapping).forEach((variable, i) => {
                this.columnMapping.push({[this.campaign.config.variables[i].name]: variable[1]})
            })
        }
    },
    watch: {
        importCompleted() {
            this.checkFormValidation();
        }
    }
}
</script>
