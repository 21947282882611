<template>
    <div v-if="campaign.campaignType === 'CAMPAIGN_MBOX'" class="step px-3">
        <div class="row mt-3">
            <div class="col-12">
                <ul class="list-group mb-3">
                    <li class="list-group-item d-flex justify-content-between">
                        <strong>{{ $t('pages.campaign-settings.authentication-key') }}</strong>
                        <code>{{ campaign.inboundChannels[0].config.mboxToken }}</code>
                    </li>
                    <li class="list-group-item d-flex justify-content-between">
                        <strong>{{ $t('campaign-id') }}</strong>
                        <code>{{ campaign.campaignUniqueId }}</code>
                    </li>
                    <li class="list-group-item d-flex justify-content-between">
                        <strong>{{ $tc('email', 1) }}</strong>
                        <code>{{ campaign.inboundChannels[0].config.mboxEmail }}</code>
                    </li>
                </ul>

                <ul id="myTab" class="nav nav-tabs" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button id="single-plaintext-tab"
                                aria-controls="single-plaintext"
                                aria-selected="true"
                                class="nav-link active"
                                data-bs-target="#single-plaintext"
                                data-bs-toggle="tab"
                                role="tab"
                                type="button">
                            {{ $t('plaintext') }} ({{ $t('single') }})
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button id="multi-plaintext-tab"
                                aria-controls="multi-plaintext"
                                aria-selected="false"
                                class="nav-link"
                                data-bs-target="#multi-plaintext"
                                data-bs-toggle="tab"
                                role="tab"
                                type="button">
                            {{ $t('plaintext') }} ({{ $t('multi') }})
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button id="single-json-tab"
                                aria-controls="single-json"
                                aria-selected="false"
                                class="nav-link"
                                data-bs-target="#single-json"
                                data-bs-toggle="tab"
                                role="tab"
                                type="button">
                            {{ $t('json') }} ({{ $t('single') }})
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button id="multi-json-tab"
                                aria-controls="multi-json"
                                aria-selected="false"
                                class="nav-link"
                                data-bs-target="#multi-json"
                                data-bs-toggle="tab"
                                role="tab"
                                type="button">
                            {{ $t('json') }} ({{ $t('multi') }})
                        </button>
                    </li>
                </ul>
                <div id="myTabContent" class="tab-content">
                    <div id="single-plaintext" aria-labelledby="single-plaintext-tab" class="tab-pane fade show active"
                         role="tabpanel">
                        <div class="p-3">
                            <pre>&lt;&lt;&lt;&lt;HEAD
authenticationKey:{{ campaign.inboundChannels[0].config.mboxToken }}
campaignId:{{ campaign.campaignUniqueId }}
format:plaintext
numberOfRecipients:1
compressed:false
encrypted:false
&lt;&lt;&lt;&lt;BODY
{{ getCampaignMboxTemplate('plaintext', 1) }}
&lt;&lt;&lt;&lt;END</pre>
                        </div>
                    </div>
                    <div id="multi-plaintext" aria-labelledby="multi-plaintext-tab" class="tab-pane fade"
                         role="tabpanel">
                        <div class="p-3">
                            <pre>&lt;&lt;&lt;&lt;HEAD
authenticationKey:{{ campaign.inboundChannels[0].config.mboxToken }}
campaignId:{{ campaign.campaignUniqueId }}
format:plaintext
numberOfRecipients:3
compressed:false
encrypted:false
&lt;&lt;&lt;&lt;BODY
{{ getCampaignMboxTemplate('plaintext', 3) }}
&lt;&lt;&lt;&lt;END</pre>
                        </div>
                    </div>
                    <div id="single-json" aria-labelledby="single-json-tab" class="tab-pane fade" role="tabpanel">
                        <div class="p-3">
                            <pre>&lt;&lt;&lt;&lt;HEAD
authenticationKey:{{ campaign.inboundChannels[0].config.mboxToken }}
campaignId:{{ campaign.campaignUniqueId }}
format:json
numberOfRecipients:1
compressed:false
encrypted:false
&lt;&lt;&lt;&lt;BODY
{{ getCampaignMboxTemplate('json', 1) }}
&lt;&lt;&lt;&lt;END</pre>
                        </div>
                    </div>
                    <div id="multi-json" aria-labelledby="multi-json-tab" class="tab-pane fade" role="tabpanel">
                        <div class="p-3">
                            <pre>&lt;&lt;&lt;&lt;HEAD
authenticationKey:{{ campaign.inboundChannels[0].config.mboxToken }}
campaignId:{{ campaign.campaignUniqueId }}
format:json
numberOfRecipients:3
compressed:false
encrypted:false
&lt;&lt;&lt;&lt;BODY
{{ getCampaignMboxTemplate('json', 3) }}
&lt;&lt;&lt;&lt;END</pre>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "ComponentMboxRecipients",
    computed: {
        campaign() {
            return this.$store.state.CAMPAIGN.campaign;
        },
    },
    data() {
        return {}
    },
    methods: {
        getCampaignMboxTemplate(format, count) {
            let html = '';
            const variableCount = this.campaign.config.variables.length;

            switch (format) {
                case 'plaintext':
                    for (let i = 0; i < count; i++) {
                        this.campaign.config.variables.forEach((variable, j) => {
                            html += '"' + variable.name + '":{' + this.$t('your-content') + '}';

                            if (j < (variableCount - 1)) {
                                html += '\n';
                            }
                        });

                        if (i < (count - 1)) {
                            html += '\n---\n'
                        }
                    }
                    break;
                case 'json':
                    html += '[\n';

                    for (let i = 0; i < count; i++) {
                        html += '\t{\n';
                        this.campaign.config.variables.forEach((variable, j) => {
                            html += '\t\t"' + variable.name + '": "{' + this.$t('your-content') + '}"';

                            if (j < (variableCount - 1)) {
                                html += ',\n';
                            }
                        });
                        html += '\n\t}';

                        if (i < (count - 1)) {
                            html += ',\n';
                        }
                    }

                    html += '\n]';
            }

            return html;
        },
    }
}
</script>
