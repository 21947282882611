<template>
    <div class="step px-3">
        <div class="row">
            <div class="col-12">
                <div class="mb-3">
                    <label class="form-label" for="csv-file">{{ $tc('csv-file', 1) }}</label>
                    <input id="csv-file"
                           ref="file"
                           :class="{'is-invalid': v.csv.name.$invalid}"
                           accept="text/csv, .csv"
                           class="form-control"
                           type="file"
                           @change="onParseCsv">
                    <div v-if="!v.csv.name.required" class="invalid-feedback">
                        {{ $t('validation.error.required.csv-file') }}
                    </div>
                </div>

                <div class="mb-3">
                    <label class="form-label" for="csv-delimiter">{{ $tc('delimiter', 1) }}</label>
                    <select id="csv-delimiter"
                            v-model="selected.csv.delimiter"
                            class="form-select">
                        <option value="">{{ $t('auto-detect') }}</option>
                        <option value=";">;</option>
                        <option value=",">,</option>
                    </select>
                </div>

                <div class="mb-3">
                    <label class="form-label" for="csv-encoding">{{ $tc('encoding', 1) }}</label>
                    <select id="csv-encoding" v-model="selected.csv.encoding" class="form-select">
                        <option v-for="(encoding, i) in encodings"
                                :key="'encoding-option-' + i"
                                :value="encoding.value">
                            {{ encoding.label }}
                        </option>
                    </select>
                </div>

                <component-loading v-if="loading.CSV" background-color="rgba(255,255,255,.6)"/>
            </div>
        </div>
    </div>
</template>

<script>
import {v4 as v4uuid} from "uuid";
import {required} from "vuelidate/lib/validators";
import S3 from "../../../lib/s3";
import AWS from "aws-sdk";
import moment from "moment";
import ComponentLoading from "../../ui/ComponentLoading";

export default {
    name: "ComponentImportRecipients",
    components: {ComponentLoading},
    computed: {
        v() {
            return this.$v;
        },
        formValid() {
            return !this.$v.$invalid;
        },
        loading() {
            return {
                CSV: false,
                CONTACT_LIST: this.$store.state.loading.CONTACT_LIST,
                EVENT_TRIGGER_CAMPAIGN: this.$store.state.loading.EVENT_TRIGGER_CAMPAIGN,
                CAMPAIGN_GET: this.$store.state.loading.CAMPAIGN_GET,
                CAMPAIGN_LIST: this.$store.state.loading.CAMPAIGN_LIST,
            }
        },
        partyUniqueId() {
            return this.$store.state.USER.user.partyUniqueId;
        },
        campaign() {
            return this.$store.state.CAMPAIGN.campaign;
        },
        temporaryCampaign() {
            return this.$store.state.CAMPAIGN.temporaryCampaign;
        },
        campaigns() {
            return this.$store.state.CAMPAIGN.campaigns;
        },
        channelUniqueId() {
            return this.$store.state.CAMPAIGN.campaign.outboundChannels[0].channelUniqueId;
        },
        encodings() {
            // ISO-8859-1
            return [
                {
                    value: 'utf-8',
                    label: 'UTF-8'
                },
                {
                    value: 'ibm866',
                    label: 'IBM866',
                },
                {
                    value: 'iso-8859-2',
                    label: 'ISO-8859-2'
                },
                {
                    value: 'iso-8859-3',
                    label: 'ISO-8859-3'
                },
                {
                    value: 'iso-8859-4',
                    label: 'ISO-8859-4'
                },
                {
                    value: 'iso-8859-5',
                    label: 'ISO-8859-5'
                },
                {
                    value: 'iso-8859-6',
                    label: 'ISO-8859-6'
                },
                {
                    value: 'iso-8859-7',
                    label: 'ISO-8859-7'
                },
                {
                    value: 'iso-8859-8',
                    label: 'ISO-8859-8'
                },
                {
                    value: 'iso-8859-8-i',
                    label: 'ISO-8859-8-I'
                },
                {
                    value: 'iso-8859-10',
                    label: 'ISO-8859-10'
                },
                {
                    value: 'iso-8859-13',
                    label: 'ISO-8859-13'
                },
                {
                    value: 'iso-8859-14',
                    label: 'ISO-8859-14'
                },
                {
                    value: 'iso-8859-15',
                    label: 'ISO-8859-15'
                },
                {
                    value: 'iso-8859-16',
                    label: 'ISO-8859-16'
                },
                {
                    value: 'koi8-r',
                    label: 'KOI8-R'
                },
                {
                    value: 'macintosh',
                    label: 'Macintosh'
                },
                {
                    value: 'iso8859-11',
                    label: 'Windows 874'
                },
                {
                    value: 'windows-1250',
                    label: 'Windows 1250'
                },
                {
                    value: 'windows-1251',
                    label: 'Windows 1251'
                },
                {
                    value: 'iso-8859-1',
                    label: 'Windows 1252'
                },
                {
                    value: 'windows-1253',
                    label: 'Windows 1253'
                },
                {
                    value: 'windows-1254',
                    label: 'Windows 1254'
                },
                {
                    value: 'windows-1255',
                    label: 'Windows 1255'
                },
                {
                    value: 'windows-1256',
                    label: 'Windows 1256'
                },
                {
                    value: 'windows-1257',
                    label: 'Windows 1257'
                },
                {
                    value: 'windows-1258',
                    label: 'Windows 1258'
                },
                {
                    value: 'x-mac-cyrillic',
                    label: 'X Mac Cyrillic'
                },
            ]
        },
        mediaLibraryUniqueId() {
            const mediaLibraries = this.$store.state.MEDIA_LIBRARY.mediaLibraries;

            if (Object.prototype.hasOwnProperty.call(mediaLibraries, 'list') && mediaLibraries.list.length > 0) {
                return mediaLibraries.list[0].mediaLibraryUniqueId;
            }

            return null;
        }
    },
    data() {
        return {
            selected: {
                csv: {
                    file: null,
                    delimiter: '',
                    encoding: 'iso-8859-1'
                },
            },
            csv: {
                columnMapping: {},
                name: "",
                data: [],
                errors: [],
                headers: [],
                delimiter: null,
                encoding: 'iso-8859-1',
                preview: 20,
            }
        }
    },
    methods: {
        onParseCsv() {
            const that = this;

            this.csv.name = this.$refs.file.files[0].name;

            this.$papa.parse(this.$refs.file.files[0], {
                header: true,
                preview: this.csv.preview,
                delimiter: this.selected.csv.delimiter,
                encoding: this.selected.csv.encoding,
                skipEmptyLines: true,
                complete: function (results) {
                    that.csv.data = results.data;
                    that.csv.errors = results.errors;
                    that.csv.headers = results.meta.fields;
                    that.csv.delimiter = results.meta.delimiter;
                    that.csv.columnMapping = {};
                    that.csv.headers.forEach(header => that.csv.columnMapping[header] = header);
                    that.$emit('setCsvVariables', results.meta.fields)
                }
            });
        },
        onSendCsv() {
            const mediaObjectUniqueId = v4uuid();
            const Body = this.$refs.file.files[0];
            const Key = mediaObjectUniqueId;
            const Bucket = process.env.VUE_APP_AWS_BUCKET_NAME + '/' + this.partyUniqueId + '/MEDIA_LIBRARY';
            const queueSize = 1;
            const params = {Key, Body, Bucket, ContentType: 'text/csv; charset=' + this.selected.csv.encoding};
            const managedUpload = new AWS.S3.ManagedUpload({service: S3, params, queueSize});

            managedUpload.send((error, data) => {
                if (error === null) {
                    const Location = data.Location;
                    const re = /(?:\.([^.]+))?$/;
                    const extension = re.exec(Body.name);

                    this.$store.dispatch('MEDIA_OBJECT/CREATE', {
                        mediaObjectUniqueId,
                        mediaObjectDescShort: Body.name.split('.').slice(0, -1).join('.'),
                        mediaObjectDescLong: null,
                        mediaObjectComment: null,
                        mediaLibraryUniqueId: this.mediaLibraryUniqueId,
                        mediaObjectType: "MEDIA_OBJECT_IMPORT",
                        mediaObjectStatus: "MEDIA_OBJECT_ACTIVE",
                        mediaProperties: {
                            extension: extension[1],
                            sizeInBytes: Body.size.toString(),
                            contentType: Body.type,
                            fullPath: Location
                        },
                    })
                        .then(() => {
                            this.$store.dispatch('PROCESS_JOB/CREATE', {
                                processJobUniqueId: null,
                                processJobDescShort: 'CONTACT_IMPORT',
                                processJobDescLong: null,
                                processJobComment: null,
                                processJobType: 'PROCESS_JOB_IMPORT',
                                processJobStatus: 'PROCESS_JOB_NEW'
                            })
                                .then(processJobResponse => {
                                    this.$emit('setProcessJobUniqueId', processJobResponse.processJobUniqueId)
                                    const workflowConfig = {
                                        processScheduleStartDt: null,
                                        processScheduleEndDt: null,
                                        processScheduleRepeatUnit: null,
                                        processScheduleRepeatValue: null,
                                        channelUniqueId: null,
                                        steps: [
                                            {
                                                stepUniqueId: v4uuid(),
                                                stepDescShort: 'Recipient Import',
                                                stepType: 'IMPORT',
                                                stepParentUniqueId: '',
                                                stepConditions: {},
                                                stepConfig: {
                                                    mediaObjectUniqueId,
                                                    processJobUniqueId: processJobResponse.processJobUniqueId,
                                                    campaignUniqueId: this.campaign.campaignUniqueId,
                                                    channelUniqueId: this.channelUniqueId,
                                                    object: 'RECIPIENT',
                                                    contentType: 'CSV',
                                                    delimiter: this.selected.csv.delimiter,
                                                    encoding: this.selected.csv.encoding,
                                                    headings: true,
                                                    columnMapping: this.csv.columnMapping
                                                }
                                            }
                                        ]
                                    }

                                    this.$store.dispatch('WORKFLOW/CREATE', {
                                        workflowUniqueId: null,
                                        workflowDescShort: 'CONTACT_IMPORT_CSV_' + moment().format('YYYYMMDDHHmmss'),
                                        workflowDescLong: 'File: ' + Body.name,
                                        workflowComment: null,
                                        workflowType: 'WORKFLOW_ONE_TIME',
                                        workflowStatus: 'WORKFLOW_ACTIVE',
                                        workflowConfig,
                                    })
                                        .then(workflowResponse => {
                                            this.$store.dispatch('CAMPAIGN/SAVE_RECIPIENT_CONFIG', {
                                                campaignUniqueId: this.campaign.campaignUniqueId,
                                                sourceType: 'IMPORT',
                                                sourceName: this.csv.name,
                                                contactUniqueIds: [],
                                                segmentUniqueIds: [],
                                                ruleUniqueId: null,
                                                importConfig: {
                                                    mediaObjectUniqueId: this.mediaLibraryUniqueId,
                                                    processJobUniqueId: processJobResponse.processJobUniqueId,
                                                    workflowUniqueId: workflowResponse.workflowUniqueId
                                                }
                                            })
                                                .then(() => {
                                                    this.$store.dispatch('CAMPAIGN/GET_RECIPIENT_CONFIG', this.campaign.campaignUniqueId);
                                                });
                                        })
                                })
                        })
                        .catch(error => {
                            this.$store.dispatch('toast/danger', {
                                title: this.$t('toast.danger.error'),
                                message: error.response.data.message
                            });
                        })
                        .catch(error => {
                            S3.deleteObject({Bucket, Key}, (error) => {
                                if (error) {
                                    console.error(error);
                                }
                            });
                            this.$store.dispatch('toast/danger', {
                                title: this.$tc('toast.danger.file-upload'),
                                message: error.response.data.message
                            });
                        });
                } else {
                    console.log(error);
                    this.$store.dispatch('toast/danger', {
                        title: this.$t('toast.danger.error'),
                        message: this.$tc('toast.danger.file-upload')
                    });
                }
            });
        },
        checkFormValidation() {
            this.$emit('validateForm', 'importRecipients', this.formValid)
        }
    },
    watch: {
        formValid() {
            this.checkFormValidation();
        }
    },
    validations: {
        csv: {
            name: {
                required
            }
        }
    }
}
</script>

