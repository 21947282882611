<template>
    <div class="step px-3">
        <form class="position-relative">
            <div class="row">
                <div class="col-12">
                    <label class="form-label h5 fw-bold" for="campaign-name">
                        {{ $t('modal.recipients-and-campaigns-configuration.dp-campaign-name') }}
                    </label>
                    <input id="campaign-name"
                           v-model="campaignName"
                           :class="{ 'is-invalid': !this.$v.campaignName.maxLength }"
                           :disabled="editingDisabled"
                           class="form-control w-100"
                           maxlength="30"
                           pattern='[^<>?":|\\/*]{0,30}'
                           required="required"
                           title="30 characters maximum, the following special characters are not allowed:"
                           type="text">
                    <p :class="{ 'invalid-feedback': !this.$v.campaignName.maxLength }" class="form-text">
                        {{ $t('modal.recipients-and-campaigns-configuration.campaign-character-limit-description') }}
                        <a v-if="campaign.outboundChannels"
                           :href="campaign.outboundChannels[0].config.printMailingAutomationUrl"
                           rel="noopener noreferrer"
                           target="_blank">
                            <font-awesome-icon icon="external-link-alt"/>
                            {{ $t('modal.recipients-and-campaigns-configuration.campaign-character-limit-url') }}
                        </a>
                    </p>
                    <p :class="{ 'text-danger': this.campaignName.length === 30, 'text-warning': this.campaignName.length > 25 }"
                       class="form-text">
                        {{ $t('n-characters-remaining', {n: 30 - this.campaignName.length}) }}
                    </p>
                </div>

                <div class="col-12">
                    <div class="alert alert-info d-flex align-items-center mb-4">
                        <font-awesome-icon class="ms-2 me-3" icon="info" size="2x"/>
                        {{ $t('alerts.info.address-field-content') }}
                    </div>
                </div>

                <div class="col-12">
                    <h2 class="h5">
                        <strong>{{ $t('modal.recipients-and-campaigns-configuration.recipient-and-or-company-name') }}</strong>
                    </h2>
                    <p class="form-text">
                        {{ $t('modal.recipients-and-campaigns-configuration.mandatory-recipient-desc') }}
                    </p>
                </div>

                <div class="col-12">
                    <ul class="list-group mb-4">
                        <li class="list-group-item">
                            <div class="d-flex align-content-center">
                                <strong class="pe-3" style="flex: 1 0 33.33%">{{ $tc('content', 1) }}</strong>
                                <strong class="pe-3" style="flex: 1 0 33.33%">
                                    {{ $t('modal.recipients-and-campaigns-configuration.dp-variable-name') }}
                                </strong>
                                <strong class="pe-3" style="flex: 1 0 33.33%">
                                    {{ $t('modal.recipients-and-campaigns-configuration.dp-variable-type') }}
                                </strong>
                            </div>
                        </li>
                        <li v-for="(variable, i) in mandatoryRecipientVariables"
                            :key="'mandatory-recipient-variable-' + i"
                            class="list-group-item d-flex w-100">
                            <div class="pe-3" style="flex: 1 0 33.33%">
                                <label :for="'mandatory-recipient-variable-' + i + '-field'" class="sr-only">
                                    <strong>{{ $tc('field', 1) }}</strong>
                                </label>
                                <select :id="'campaign-variable-' + i + '-field'"
                                        v-model="variable.field"
                                        class="form-select form-select-sm"
                                        disabled="disabled">
                                    <option v-for="(key, value) in fields"
                                            :key="'variable-field-' + key"
                                            :value="value">
                                        {{ $t('variables.' + value) }}
                                    </option>
                                    <option :value="null">{{ $t('variables.OTHER') }}</option>
                                </select>
                            </div>

                            <div class="pe-3" style="flex: 1 0 33.33%">
                                <label :for="'mandatory-recipient-variable-' + i + '-name'" class="form-label sr-only">
                                    <strong>{{ $tc('variable-name', 1) }}</strong>
                                </label>
                                <input :id="'mandatory-recipient-variable-' + i + '-name'"
                                       v-model="variable.name"
                                       :class="{ 'is-invalid': v.variables.$each[i].name.$invalid }"
                                       :disabled="editingDisabled"
                                       :name="'campaign_variable[' + i + '][name]'"
                                       class="form-control form-control-sm w-100"
                                       required="required"
                                       type="text">
                                <div v-if="!v.variables.$each[i].name.required" class="invalid-feedback">
                                    {{ $t('validation.error.required.variable-name') }}
                                </div>
                                <div v-if="!v.variables.$each[i].name.maxLength" class="invalid-feedback">
                                    {{ $t('validation.error.maxLength.variable-name', {count: v.variables.$each[i].name.$params.maxLength.max}) }}
                                </div>
                            </div>

                            <div class="pe-3" style="flex: 1 0 33.33%">
                                <label :for="'mandatory-recipient-variable-' + i + '-type'"
                                       class="form-label sr-only">
                                    <strong>{{ $tc('variable-type', 1) }}</strong>
                                </label>
                                <select :id="'campaign-variable-' + i + '-type'"
                                        v-model="variable.type"
                                        :name="'campaign_variable[' + i + '][type]'"
                                        class="form-select form-select-sm w-100"
                                        disabled="disabled"
                                        required="required">
                                    <option v-for="(value, i) in variableType"
                                            :key="'variable-new-variable-type-' + i"
                                            :value="value">
                                        {{ value }}
                                    </option>
                                </select>
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="col-12">
                    <h2 class="h5"><strong>{{ $tc('address-field', 2) }}</strong></h2>
                    <p class="form-text">
                        {{ $t('modal.recipients-and-campaigns-configuration.mandatory-address-field-desc') }}
                    </p>
                </div>

                <div class="col-12">
                    <ul class="list-group mb-4">
                        <li class="list-group-item">
                            <div class="d-flex align-content-center">
                                <strong class="pe-3" style="flex: 1 0 33.33%">{{ $tc('content', 1) }}</strong>
                                <strong class="pe-3" style="flex: 1 0 33.33%">
                                    {{ $t('modal.recipients-and-campaigns-configuration.dp-variable-name') }}
                                </strong>
                                <strong class="pe-3" style="flex: 1 0 33.33%">
                                    {{ $t('modal.recipients-and-campaigns-configuration.dp-variable-type') }}
                                </strong>
                            </div>
                        </li>
                        <li v-for="(variable, i) in mandatoryAddressVariables"
                            :key="'mandatory-address-variable-' + i"
                            class="list-group-item d-flex w-100">
                            <div class="pe-3" style="flex: 1 0 33.33%">
                                <label :for="'mandatory-address-variable-' + i + '-field'"
                                       class="sr-only">
                                    <strong>{{ $tc('field', 1) }}</strong>
                                </label>
                                <select :id="'campaign-variable-' + i + '-field'"
                                        v-model="variable.field"
                                        class="form-select form-select-sm"
                                        disabled="disabled">
                                    <option v-for="(key, value) in fields"
                                            :key="'variable-field-' + key"
                                            :value="value">
                                        {{ $t('variables.' + value) }}
                                    </option>
                                    <option :value="null">{{ $t('variables.OTHER') }}</option>
                                </select>
                            </div>

                            <div class="pe-3" style="flex: 1 0 33.33%">
                                <label :for="'mandatory-address-variable-' + i + '-name'"
                                       class="form-label sr-only">
                                    <strong>{{ $tc('variable-name', 1) }}</strong>
                                </label>
                                <input :id="'mandatory-address-variable-' + i + '-name'"
                                       v-model="variable.name"
                                       :class="{ 'is-invalid': v.variables.$each[i+3].name.$invalid }"
                                       :disabled="editingDisabled"
                                       :name="'campaign_variable[' + i + '][name]'"
                                       class="form-control form-control-sm w-100"
                                       required="required"
                                       type="text">
                                <div v-if="!v.variables.$each[i+3].name.required" class="invalid-feedback">
                                    {{ $t('validation.error.required.variable-name') }}
                                </div>
                                <div v-if="!v.variables.$each[i+3].name.maxLength" class="invalid-feedback">
                                    {{ $t('validation.error.maxLength.variable-name', {count: v.variables.$each[i].name.$params.maxLength.max}) }}
                                </div>
                            </div>

                            <div class="pe-3" style="flex: 1 0 33.33%">
                                <label :for="'mandatory-address-variable-' + i + '-type'"
                                       class="form-label sr-only">
                                    <strong>{{ $tc('variable-type', 1) }}</strong>
                                </label>
                                <select :id="'campaign-variable-' + i + '-type'"
                                        v-model="variable.type"
                                        :disabled="i < 7"
                                        :name="'campaign_variable[' + i + '][type]'"
                                        class="form-select form-select-sm w-100"
                                        required="required">
                                    <option v-for="(value, i) in variableType"
                                            :key="'variable-new-variable-type-' + i"
                                            :value="value">
                                        {{ value }}
                                    </option>
                                </select>
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="col-12">
                    <h2 class="h5"><strong>
                        {{ $t('modal.recipients-and-campaigns-configuration.additional-or-optional-fields') }}
                    </strong></h2>
                    <p class="form-text">
                        {{ $t('modal.recipients-and-campaigns-configuration.additional-optional-field-desc') }}
                    </p>
                    <div class="mb-3 text-right">
                        <button :disabled="variables.length > 26 || editingDisabled"
                                class="btn btn-light"
                                type="button"
                                @click="onAddVariable">
                            <font-awesome-icon class="me-1" icon="plus"/>
                            {{ $t('actions.add-variable') }}
                        </button>
                    </div>
                    <div v-if="variables.length > 26" class="alert alert-info d-flex align-items-center">
                        <font-awesome-icon class="me-3" icon="info-circle" size="2x"/>
                        {{ $t('modal.recipients-and-campaigns-configuration.variables-maximum-reached') }}
                    </div>
                </div>

                <div class="col-12">
                    <ul class="list-group mb-3">
                        <li class="list-group-item d-flex">
                            <strong class="pe-3 w-100">
                                {{ $t('modal.recipients-and-campaigns-configuration.dp-variable-name') }}
                            </strong>
                            <strong class="pe-3 w-100">
                                {{ $t('modal.recipients-and-campaigns-configuration.dp-variable-type') }}
                            </strong>
                        </li>
                        <li v-if="optionalVariables.length === 0" class="list-group-item d-flex w-100">
                            {{ $t('modal.recipients-and-campaigns-configuration.no-additional-optional-fields-defined') }}
                        </li>
                        <li v-for="(variable, i) in optionalVariables"
                            :key="'mandatory-recipient-variable-' + i"
                            class="list-group-item d-flex w-100">

                            <div class="pe-3" style="flex: 1 0 calc(50% - 10px)">
                                <label :for="'additional-optional-variable-' + i + '-name'"
                                       class="form-label sr-only">
                                    <strong>{{ $tc('variable-name', 1) }}</strong>
                                </label>
                                <input :id="'additional-optional-variable-' + i + '-name'"
                                       v-model="variable.name"
                                       :class="{ 'is-invalid': v.variables.$each[i+7].name.$invalid }"
                                       :disabled="editingDisabled"
                                       :name="'campaign_variable[' + i + '][name]'"
                                       class="form-control form-control-sm w-100"
                                       required="required"
                                       type="text">
                                <div v-if="!v.variables.$each[i+7].name.required" class="invalid-feedback">
                                    {{ $t('validation.error.required.variable-name') }}
                                </div>
                                <div v-if="!v.variables.$each[i+7].name.maxLength" class="invalid-feedback">
                                    {{ $t('validation.error.maxLength.variable-name', {count: v.variables.$each[i].name.$params.maxLength.max}) }}
                                </div>
                            </div>

                            <div class="pe-3" style="flex: 1 0 calc(50% - 10px)">
                                <label :for="'additional-optional-variable-' + i + '-type'"
                                       class="form-label sr-only">
                                    <strong>{{ $tc('variable-type', 1) }}</strong>
                                </label>
                                <select :id="'campaign-variable-' + i + '-type'"
                                        v-model="variable.type"
                                        :disabled="editingDisabled"
                                        :name="'campaign_variable[' + i + '][type]'"
                                        class="form-select form-select-sm w-100"
                                        required="required">
                                    <option v-for="(value, i) in variableType"
                                            :key="'variable-new-variable-type-' + i"
                                            :value="value">
                                        {{ value }}
                                    </option>
                                </select>
                            </div>

                            <div class="text-center" style="flex: 1 0 30px">
                                <button :disabled="editingDisabled"
                                        :title="$tc('actions.delete')"
                                        class="btn btn-sm btn-outline-danger"
                                        type="button"
                                        @click="onRemoveVariable(i + 7)">
                                    <span class="sr-only">{{ $tc('actions.delete') }}</span>
                                    <font-awesome-icon icon="trash"/>
                                </button>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <component-loading v-if="loading.CAMPAIGN_GET || loading.CAMPAIGN_UPDATE_CHANNEL"
                               background-color="rgba(255,255,255,.6)"/>
        </form>
    </div>
</template>
<script>
import {maxLength, required} from "vuelidate/lib/validators";
import ComponentLoading from "../../../components/ui/ComponentLoading";

export default {
    name: "ComponentNewVariableConfiguration",
    components: {ComponentLoading},
    computed: {
        v() {
            return this.$v;
        },
        formValid() {
            return !this.$v.$invalid;
        },
        loading() {
            return {
                CSV: false,
                CONTACT_LIST: this.$store.state.loading.CONTACT_LIST,
                EVENT_TRIGGER_CAMPAIGN: this.$store.state.loading.EVENT_TRIGGER_CAMPAIGN,
                CAMPAIGN_GET: this.$store.state.loading.CAMPAIGN_GET,
                CAMPAIGN_LIST: this.$store.state.loading.CAMPAIGN_LIST,
            }
        },
        campaign() {
            return this.$store.state.CAMPAIGN.campaign;
        },
        mandatoryRecipientVariables() {
            return this.variables.slice(0, 3);
        },
        mandatoryAddressVariables() {
            return this.variables.slice(3, 7);
        },
        optionalVariables() {
            return this.variables.slice(7, this.variables.length);
        },
        versioning() {
            if (this.configExists) {
                let version = this.campaign.config.versioning;
                version.variables = this.campaign.config.versioning.variables + 1;
                return version;
            } else {
                return {
                    variables: 2,
                    recipients: 1,
                    recipientMapping: 1
                }
            }
        },
        editingDisabled() {
            if (this.pageEditable) {
                return false;
            }

            return !!this.campaignVariablesConfigured;
        }
    },
    props: {
        fields: {
            Type: Object,
            required: true
        },
        variableType: {
            Type: Object,
            required: true
        },
        campaignVariablesConfigured: {
            Type: Boolean,
            required: false
        },
        configExists: {
            Type: Boolean,
            required: false
        },
        pageEditable: {
            Type: Boolean,
            required: false
        }
    },
    data() {
        return {
            campaignName: '',
            variables: [
                {
                    name: this.$tc('first-name', 1),
                    type: 'string',
                    field: 'FIRST_NAME'
                },
                {
                    name: this.$tc('last-name', 1),
                    type: 'string',
                    field: 'LAST_NAME'
                },
                {
                    name: this.$tc('company', 1),
                    type: 'string',
                    field: 'COMPANY'
                },
                {
                    name: this.$tc('street', 1),
                    type: 'string',
                    field: 'STREET'
                },
                {
                    name: this.$tc('postcode', 1),
                    type: 'zip',
                    field: 'ZIP_CODE'
                },
                {
                    name: this.$tc('city', 1),
                    type: 'string',
                    field: 'CITY'
                },
                {
                    name: this.$tc('country', 1),
                    type: 'countryCode',
                    field: 'COUNTRY'
                }
            ]
        }
    },
    methods: {
        onAddVariable() {
            this.variables.push({
                name: '',
                type: 'string',
                field: null
            });
        },
        onRemoveVariable(index) {
            this.variables.splice(index, 1);
        },
        saveConfiguration() {
            this.$store.dispatch('CAMPAIGN/UPDATE_CHANNEL', {
                campaignUniqueId: this.campaign.campaignUniqueId,
                channelUniqueId: this.campaign.outboundChannels[0].channelUniqueId,
                saveExternalCampaign: false,
                configData: {
                    versioning: this.versioning,
                    campaignData: {
                        campaignName: this.campaignName,
                        startDate: '2020-01-01',
                        endDate: null
                    },
                    printNodes: [
                        {
                            printNodeId: 'Print_Node_01',
                            description: 'Print_Node_01'
                        }
                    ],
                    variables: this.variables,
                    staticValues: this.campaign.config ? this.campaign.config.staticValues : null,
                    sourceColumns: this.campaign.config ? this.campaign.config.sourceColumns : null,
                    recipientMapping: this.campaign.config ? this.campaign.config.recipientMapping : null,
                    configSourceType: this.campaign.config ? this.campaign.config.configSourceType : 'NEW_CAMPAIGN',
                    printMailingAutomationCampaignUniqueId: this.campaign.config
                        ? this.campaign.config.printMailingAutomationCampaignUniqueId
                        : null,
                }
            })
                .catch(() => {
                    this.$store.dispatch('toast/danger', {
                        title: this.$tc('toast.danger.error'),
                        message: this.$tc('toast.danger.campaign-channel-updated')
                    });
                });
        },
        checkFormValidation() {
            this.$emit('validateForm', 'newCampaign', this.formValid)
        }
    },
    validations: {
        campaignName: {
            required,
            maxLength: maxLength(30)
        },
        variables: {
            $each: {
                name: {
                    required,
                    maxLength: maxLength(30)
                }
            }
        }
    },
    watch: {
        campaign(campaign) {
            if (campaign.config && campaign.config['campaignData']['campaignName']) {
                this.campaignName = campaign.config.campaignData.campaignName;
            } else {
                this.campaignName = campaign.campaignDescShort;
            }
        },
        formValid() {
            this.checkFormValidation();
        }
    },
    beforeMount() {
        if (this.campaignVariablesConfigured) {
            this.variables = this.campaign.config.variables;
            this.campaignName = this.campaign.config.campaignData.campaignName;
        } else {
            this.campaignName = this.campaign.campaignDescShort.substr(0, 30);
        }
    }
}
</script>
