<template>
    <div style="height: 100%">
        <div class="modal-body p-0" style="height: calc(100% - 68px)">

            <div :class="{'overflow-hidden' : step === 1}" class="modal-wrapper">
                <component-stepper :step="step" @step="onChangeStep"/>

                <!-- STEP 1 - CAMPAIGN STARTING POINT -->
                <div :class="getStepClass(1)" class="step px-3">
                    <div class="row">
                        <div class="col-12">
                            <div class="list-group list-group-horizontal">
                                <div :class="getCampaignStartingPointClass('NEW_CAMPAIGN')"
                                     class="list-group-item list-group-item-action p-4 d-flex align-items-start"
                                     role="button"
                                     style="border-bottom-left-radius: 0; border-bottom-right-radius: 0;"
                                     @click="onSelectMode('NEW_CAMPAIGN')">
                                    <font-awesome-icon class="me-4 text-primary"
                                                       icon="plus-square"
                                                       size="4x"
                                                       style="max-width: 36px;"/>
                                    <div>
                                        <h4 class="mb-2 fs-5">
                                            {{ $t('modal.recipients-and-campaigns-configuration.new-campaign-heading') }}
                                        </h4>
                                        <p class="mb-0">
                                            {{ $t('modal.recipients-and-campaigns-configuration.new-campaign-description') }}
                                        </p>
                                    </div>
                                </div>
                                <div :class="getCampaignStartingPointClass('EXISTING_CAMPAIGN')"
                                     class="list-group-item list-group-item-action p-4 d-flex align-items-start"
                                     role="button"
                                     style="border-bottom-left-radius: 0;"
                                     @click="onSelectMode('EXISTING_CAMPAIGN')">
                                    <font-awesome-icon class="me-4 text-primary"
                                                       icon="recycle"
                                                       size="4x"
                                                       style="max-width: 36px;"/>
                                    <div>
                                        <h4 class="mb-2 fs-5">
                                            {{ $t('modal.recipients-and-campaigns-configuration.reuse-campaign-heading') }}
                                        </h4>
                                        <p class="mb-0">
                                            {{ $t('modal.recipients-and-campaigns-configuration.reuse-campaign-description') }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="list-group list-group-horizontal w-50">
                                <div :class="getCampaignStartingPointClass('PREVIOUS_CAMPAIGN')"
                                     class="list-group-item list-group-item-action p-4 d-flex align-items-start border-top-0"
                                     role="button"
                                     style="border-top-left-radius: 0; border-top-right-radius: 0"
                                     @click="onSelectMode('PREVIOUS_CAMPAIGN')">
                                    <font-awesome-icon class="me-4 text-primary"
                                                       icon="copy"
                                                       size="4x"
                                                       style="max-width: 36px;"/>
                                    <div>
                                        <h4 class="mb-2 fs-5">
                                            {{ $t('modal.recipients-and-campaigns-configuration.previous-campaign-heading') }}
                                        </h4>
                                        <p class="mb-0">
                                            {{ $t('modal.recipients-and-campaigns-configuration.previous-campaign-description') }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <component-new-variable-configuration ref="newVariableConfiguration"
                                                      :campaignVariablesConfigured="campaignVariablesConfigured"
                                                      :class="getStepClass(2)"
                                                      :configExists="configExists"
                                                      :fields="campaignVariables"
                                                      :pageEditable="pageEditable[2]"
                                                      :variableType="campaignVariableTypes"
                                                      @validateForm="validateForm"/>

                <component-reuse-variable-configuration ref="reuseVariableConfiguration"
                                                        :campaignVariablesConfigured="campaignVariablesConfigured"
                                                        :class="getStepClass(3)"
                                                        :configExists="configExists"
                                                        :fields="campaignVariables"
                                                        :variableType="campaignVariableTypes"
                                                        @validateForm="validateForm"/>

                <component-update-variable-configuration ref="updateVariableConfiguration"
                                                         :campaignVariablesConfigured="campaignVariablesConfigured"
                                                         :class="getStepClass(4)"
                                                         :configExists="configExists"
                                                         :fields="campaignVariables"
                                                         :variableType="campaignVariableTypes"
                                                         @validateForm="validateForm"/>

                <div :class="getStepClass(5)" class="step px-3">
                    <div class="list-group list-group-horizontal">
                        <div v-if="campaign.campaignType === 'CAMPAIGN_MBOX'"
                             :class="getRecipientsSourceClass('MBOX')"
                             class="list-group-item list-group-item-action p-4 d-flex align-items-start w-50"
                             role="button"
                             @click="onSelectMode('MBOX')">
                            <font-awesome-icon class="me-4 text-primary"
                                               icon="envelope"
                                               size="4x"
                                               style="max-width: 36px;"/>
                            <div>
                                <h4 class="mb-3">
                                    {{ $t('modal.recipients-and-campaigns-configuration.recipients-mbox-heading') }}
                                </h4>
                                <p class="mb-0">
                                    {{ $t('modal.recipients-and-campaigns-configuration.recipients-mbox-description') }}
                                </p>
                            </div>
                        </div>
                        <div v-if="!['CAMPAIGN_AUTOMATED_RULE_BASED', 'CAMPAIGN_AUTOMATED_EVENT_BASED', 'CAMPAIGN_MBOX'].includes(campaign.campaignType)"
                             :class="getRecipientsSourceClass('CONTACT')"
                             class="list-group-item list-group-item-action p-4 d-flex align-items-start"
                             role="button"
                             style="border-bottom-left-radius: 0;"
                             @click="onSelectMode('CONTACT')">
                            <font-awesome-icon class="me-4 text-primary"
                                               icon="address-book"
                                               size="4x"
                                               style="max-width: 36px;"/>
                            <div>
                                <h4 class="mb-3">
                                    {{ $t('modal.recipients-and-campaigns-configuration.recipients-contacts-heading') }}
                                </h4>
                                <p class="mb-0">
                                    {{ $t('modal.recipients-and-campaigns-configuration.recipients-contacts-description') }}
                                </p>
                            </div>
                        </div>
                        <div v-if="!['CAMPAIGN_AUTOMATED_RULE_BASED', 'CAMPAIGN_AUTOMATED_EVENT_BASED', 'CAMPAIGN_MBOX'].includes(campaign.campaignType)"
                             :class="getRecipientsSourceClass('SEGMENT')"
                             class="list-group-item list-group-item-action p-4 d-flex align-items-start"
                             role="button"
                             style="border-bottom-right-radius: 0;"
                             @click="onSelectMode('SEGMENT')">
                            <font-awesome-icon class="me-4 text-primary"
                                               icon="chart-pie"
                                               size="4x"
                                               style="max-width: 36px;"/>
                            <div>
                                <h4 class="mb-3">
                                    {{ $t('modal.recipients-and-campaigns-configuration.recipients-segment-heading') }}
                                </h4>
                                <p class="mb-0">
                                    {{ $t('modal.recipients-and-campaigns-configuration.recipients-segment-description') }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="list-group list-group-horizontal">
                        <div v-if="campaign.campaignType !== 'CAMPAIGN_MBOX'"
                             :class="!['CAMPAIGN_AUTOMATED_RULE_BASED', 'CAMPAIGN_AUTOMATED_EVENT_BASED'].includes(campaign.campaignType) ? getRecipientsSourceClass('RULE')  : 'w-50' && getRecipientsSourceClass('RULE')"
                             :style="['CAMPAIGN_AUTOMATED_RULE_BASED', 'CAMPAIGN_AUTOMATED_EVENT_BASED'].includes(campaign.campaignType) ? '' : 'border-top-left-radius: 0; border-top: 0;'"
                             class="list-group-item list-group-item-action p-4 d-flex align-items-start"
                             role="button"
                             @click="onSelectMode('RULE')">

                            <font-awesome-icon class="me-4 text-primary"
                                               icon="clipboard-check"
                                               size="4x"
                                               style="max-width: 36px;"/>
                            <div>
                                <h4 class="mb-3">
                                    {{ $t('modal.recipients-and-campaigns-configuration.recipients-rule-heading') }}
                                </h4>
                                <p class="mb-0">
                                    {{ $t('modal.recipients-and-campaigns-configuration.recipients-rule-description') }}
                                </p>
                            </div>
                        </div>
                        <div v-if="!['CAMPAIGN_AUTOMATED_RULE_BASED', 'CAMPAIGN_AUTOMATED_EVENT_BASED', 'CAMPAIGN_MBOX'].includes(campaign.campaignType)"
                             :class="getRecipientsSourceClass('IMPORT')"
                             class="list-group-item list-group-item-action p-4 d-flex align-items-start border-top-0"
                             role="button"
                             style="border-top-right-radius: 0;"
                             @click="onSelectMode('IMPORT')">
                            <font-awesome-icon class="me-4 text-primary"
                                               icon="file-csv"
                                               size="4x"
                                               style="max-width: 36px;"/>
                            <div>
                                <h4 class="mb-3">
                                    {{ $t('modal.recipients-and-campaigns-configuration.recipients-csv-heading') }}</h4>
                                <p class="mb-0">
                                    {{ $t('modal.recipients-and-campaigns-configuration.recipients-csv-description') }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <component-contact-recipients ref="contactRecipients"
                                              :class="getStepClass(6)"
                                              :recipientsConfigured="recipientsConfigured"
                                              @validateForm="validateForm"/>

                <component-segments-recipients ref="segmentsRecipients"
                                               :class="getStepClass(7)"
                                               :pageEditable="pageEditable[7]"
                                               :recipientsConfigured="recipientsConfigured"
                                               @validateForm="validateForm"/>

                <component-rule-recipients ref="ruleRecipients"
                                           :class="getStepClass(8)"
                                           :recipientsConfigured="recipientsConfigured"
                                           @validateForm="validateForm"/>

                <component-mbox-recipients :class="getStepClass(9)"/>

                <component-import-recipients ref="importRecipients"
                                             :class="getStepClass(10)"
                                             @setCsvVariables="setCsvVariables"
                                             @setProcessJobUniqueId="setProcessJobUniqueId"
                                             @validateForm="validateForm"/>

                <component-import-variable-mapping ref="importVariableMapping"
                                                   :class="getStepClass(11)"
                                                   :csvVariables="csvVariables"
                                                   @validateForm="validateForm"/>

                <component-import-data-preview ref="importDataPreview"
                                               :class="getStepClass(12)"
                                               :csvVariables="csvVariables"
                                               :processJobUniqueId="processJobUniqueId"
                                               :recipientsConfigured="recipientsConfigured"
                                               @validateForm="validateForm"/>

                <component-contacts-variable-mapping ref="contactsVariableMapping"
                                                     :class="getStepClass(13)"
                                                     @validateForm="validateForm"/>

                <component-configuration-summary ref="configurationSummary"
                                                 :campaignSent="campaignSent"
                                                 :class="getStepClass(14)"
                                                 :variablesMapped="variablesMapped"
                                                 @validateForm="validateForm"/>
            </div>
        </div>

        <!-- Footer -->
        <div class="modal-footer justify-content-between">
            <button v-if="canShowBack"
                    class="btn btn-secondary"
                    @click="onClickBack">
                {{ $t('actions.back') }}
            </button>
            <div v-if="step === 1 || step === 2"></div>
            <div class="d-flex align-items-center">
                <button v-if="pageEditable[step] === true"
                        class="btn btn-outline-danger me-2"
                        type="button"
                        @click="pageEditable[step] = false">
                    {{ $t('actions.cancel') }}
                </button>
                <button v-if="canShowEdit"
                        class="btn btn-outline-primary me-2"
                        type="button"
                        @click="onClickEdit()">
                    {{ $t('actions.edit') }}
                </button>
                <button v-if="canShowContinue"
                        :disabled="!formValid"
                        class="btn btn-primary"
                        type="button"
                        @click="onClickContinue">
                    {{ $t('actions.continue') }}
                </button>
                <button v-if="step === 1 && pageEditable[2] === true"
                        class="btn btn-outline-danger"
                        type="button"
                        @click="step = 2">
                    {{ $t('actions.back') }}
                </button>
                <button v-if="canShowSaveAndContinue"
                        :disabled="!formValid"
                        class="btn btn-primary"
                        type="button"
                        @click="onClickSaveAndContinue">
                    {{ $t('actions.save-and-continue') }}
                </button>
                <button v-if="step === 14"
                        :disabled="!formValid"
                        class="btn btn-primary"
                        type="button"
                        @click="updateCampaignChannel()">
                    {{
                        campaignSent
                            ? $t('modal.recipients-and-campaigns-configuration.update-dp-campaign-button')
                            : $t('modal.recipients-and-campaigns-configuration.create-dp-campaign-button')
                    }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import ComponentNewVariableConfiguration from './variable-configuration/ComponentNewVariableConfiguration';
import ComponentReuseVariableConfiguration from './variable-configuration/ComponentReuseVariableConfiguration';
import ComponentUpdateVariableConfiguration from './variable-configuration/ComponentUpdateVariableConfiguration';
import ComponentContactRecipients from './recipients-configuration/ComponentContactRecipients';
import ComponentSegmentsRecipients from './recipients-configuration/ComponentSegmentsRecipients';
import ComponentRuleRecipients from './recipients-configuration/ComponentRuleRecipients';
import ComponentMboxRecipients from './recipients-configuration/ComponentMboxRecipients';
import ComponentImportRecipients from './recipients-configuration/ComponentImportRecipients';
import ComponentImportVariableMapping from './recipients-configuration/ComponentImportVariableMapping';
import ComponentContactsVariableMapping from './recipients-configuration/ComponentContactsVariableMapping';
import ComponentImportDataPreview from './recipients-configuration/ComponentImportDataPreview';
import ComponentConfigurationSummary from './recipients-configuration/ComponentConfigurationSummary';
import ComponentStepper from "./ComponentStepper";


export default {
    name: "ComponentCampaignSettings",
    components: {
        ComponentStepper,
        ComponentNewVariableConfiguration,
        ComponentReuseVariableConfiguration,
        ComponentUpdateVariableConfiguration,
        ComponentContactRecipients,
        ComponentSegmentsRecipients,
        ComponentRuleRecipients,
        ComponentMboxRecipients,
        ComponentImportRecipients,
        ComponentImportVariableMapping,
        ComponentContactsVariableMapping,
        ComponentImportDataPreview,
        ComponentConfigurationSummary
    },
    computed: {
        loading() {
            return {
                CSV: false,
                CONTACT_LIST: this.$store.state.loading.CONTACT_LIST,
                EVENT_TRIGGER_CAMPAIGN: this.$store.state.loading.EVENT_TRIGGER_CAMPAIGN,
                CAMPAIGN_GET: this.$store.state.loading.CAMPAIGN_GET,
                CAMPAIGN_LIST: this.$store.state.loading.CAMPAIGN_LIST,
            }
        },
        campaign() {
            return this.$store.state.CAMPAIGN.campaign;
        },
        configExists() {
            const config = this.$store.state.CAMPAIGN.campaign.config;

            if (config === null) {
                return false;
            }

            return config.campaignData !== null;
        },
        campaignVariablesConfigured() {
            const config = this.campaign.config;

            if (config && Object.hasOwnProperty.call(config, 'variables')) {
                return !!this.campaign.config.variables;
            }

            return false;
        },
        recipientsConfigured() {
            if (this.recipientConfig && Object.hasOwnProperty.call(this.recipientConfig, 'sourceType')) {
                return this.recipientConfig.sourceType !== 'UNDEFINED';
            }

            return false;
        },
        recipients() {
            return this.$store.state.CAMPAIGN.recipients;
        },
        variablesMapped() {
            const config = this.$store.state.CAMPAIGN.campaign.config;

            if (config && Object.hasOwnProperty.call(config, 'recipientMapping')) {
                return !!config.recipientMapping;
            }

            return false;
        },
        campaignSent() {
            return this.$store.state.CAMPAIGN.campaign.campaignStatus === 'CAMPAIGN_SUBMITTED';
        },
        recipientConfig() {
            return this.$store.state.CAMPAIGN.recipientConfig;
        },
        formValid() {
            switch (this.step) {
                case 2:
                    return this.valid.newCampaign;
                case 3:
                    return this.valid.reusedCampaign;
                case 4:
                    return this.valid.previousCampaign;
                case 6:
                    return this.valid.contactRecipients;
                case 7:
                    return this.valid.segmentsRecipients;
                case 8:
                    return this.valid.ruleRecipients;
                case 10:
                    return this.valid.importRecipients;
                case 11:
                    return this.valid.importVariableMapping;
                case 12:
                    return this.valid.importCompleted;
                case 13:
                    return this.valid.variableMapping;
                case 14:
                    return this.valid.updateCampaign;
            }
            return true;
        },
        canShowBack() {
            if (this.step === 1) {
                return false;
            }

            if ([2,3,4].indexOf(this.step) !== -1) {
                return this.campaign.config === null;
            }

            return this.step > 5;
        },
        canShowEdit() {
            if (this.pageEditable[this.step] === true) {
                return false;
            }

            switch (this.step) {
                case 1:
                    return false;
                case 2:
                    return !!this.campaignVariablesConfigured;
                case 5:
                    return this.recipientsConfigured;
            }

            return false;
        },
        canShowContinue() {
            if (this.step === 14) {
                return false;
            }

            switch (this.step) {
                case 1:
                case 3:
                case 4:
                    return false;
                case 2:
                    return this.campaign.config !== null && this.pageEditable[2] === false;
                case 5:
                    return this.recipientsConfigured && this.pageEditable[5] === false;
                case 6:
                case 7:
                case 8:
                case 10:
                case 11:
                case 12:
                case 13:
                    return false;
                default:
                    return true;
            }
        },
        canShowSaveAndContinue() {
            if (this.step === 14) {
                return false;
            }

            switch (this.step) {
                case 1:
                    return false;
                case 2:
                    return this.campaign.config === null || this.pageEditable[2] === true;
                case 5:
                    return false;
                default:
                    return true;
            }
        }
    },
    data() {
        return {
            campaignVariables: {
                FIRST_NAME: this.$tc('first-name', 1),
                LAST_NAME: this.$tc('last-name', 1),
                COMPANY: this.$tc('company', 1),
                STREET: this.$tc('street', 1),
                ZIP_CODE: this.$tc('postcode', 1),
                CITY: this.$tc('city', 1),
                COUNTRY: this.$tc('country', 1)
            },
            campaignVariableTypes: {
                integer: 'integer',
                string: 'string',
                boolean: 'boolean',
                float: 'float',
                date: 'date',
                zip: 'zip',
                countryCode: 'countryCode',
                image: 'image'
            },
            processJobUniqueId: null,
            csvVariables: null,
            valid: {
                newCampaign: false,
                reusedCampaign: false,
                previousCampaign: false,
                contactRecipients: false,
                segmentsRecipients: false,
                ruleRecipients: false,
                importRecipients: false,
                variableMapping: false,
                importVariableMapping: false,
                importCompleted: false,
                updateCampaign: true
            },
            campaignName: '',
            variables: [
                {
                    name: this.$tc('first-name', 1),
                    type: 'string',
                    field: 'FIRST_NAME'
                },
                {
                    name: this.$tc('last-name', 1),
                    type: 'string',
                    field: 'LAST_NAME'
                },
                {
                    name: this.$tc('company', 1),
                    type: 'string',
                    field: 'COMPANY'
                },
                {
                    name: this.$tc('street', 1),
                    type: 'string',
                    field: 'STREET'
                },
                {
                    name: this.$tc('postcode', 1),
                    type: 'zip',
                    field: 'ZIP_CODE'
                },
                {
                    name: this.$tc('city', 1),
                    type: 'string',
                    field: 'CITY'
                },
                {
                    name: this.$tc('country', 1),
                    type: 'countryCode',
                    field: 'COUNTRY'
                }
            ],
            mode: {
                campaign: null,
                recipients: null
            },
            pageEditable: {
                1: false,
                2: false,
                3: false,
                4: false,
                5: false,
                6: false,
                7: false,
                8: false,
                9: false,
                10: false,
                11: false,
                12: false,
                13: false,
                14: false,
            },
            selectedFields: [],
            step: 1
        }
    },
    methods: {
        onChangeStep(step) {
            this.step = step;
        },
        leaveEditMode() {
            this.pageEditable = {
                1: false,
                2: false,
                3: false,
                4: false,
                5: false,
                6: false,
                7: false,
                8: false,
                9: false,
                10: false,
                11: false,
                12: false,
                13: false,
                14: false,
            }
        },
        listCampaigns(filter) {
            this.$store.dispatch('CAMPAIGN/LIST', {
                filter: filter,
                order: {meta_insert_dt: "DESC"},
                page: 1,
                itemsPerPage: null,
                limit: null,
            })
        },
        onClickEdit() {
            this.pageEditable[this.step] = true;
            this.$forceUpdate()
        },
        async getCampaign(campaignUniqueId) {
            await this.$store.dispatch('CAMPAIGN/GET_TEMPORARY', campaignUniqueId);

            if (this.temporaryCampaign.config) {
                this.variables = this.temporaryCampaign.config.variables;
            }

            if (this.mode.campaign === 'EXISTING_CAMPAIGN') {
                this.campaignName = this.temporaryCampaign.campaignDescShort + '-duplicate';
            } else {
                this.campaignName = this.temporaryCampaign.campaignDescShort;
            }
        },
        updateCampaignChannel() {
            this.$store.dispatch('CAMPAIGN/UPDATE_CHANNEL', {
                campaignUniqueId: this.campaign.campaignUniqueId,
                channelUniqueId: this.campaign.outboundChannels[0].channelUniqueId,
                saveExternalCampaign: true,
                configData: {
                    versioning: this.campaign.config.versioning,
                    campaignData: {
                        campaignName: this.campaign.config.campaignData.campaignName,
                        startDate: '2020-01-01',
                        endDate: null
                    },
                    printNodes: [
                        {
                            printNodeId: 'Print_Node_01',
                            description: 'Print_Node_01'
                        }
                    ],
                    variables: this.campaign.config.variables,
                    staticValues: this.campaign.config.staticValues,
                    sourceColumns: this.campaign.config.sourceColumns,
                    recipientMapping: this.campaign.config.recipientMapping,
                    configSourceType: this.campaign.config.configSourceType,
                    printMailingAutomationCampaignUniqueId: this.campaign.config.printMailingAutomationCampaignUniqueId
                },
            })
                .then(() => {
                    this.$store.dispatch('toast/success', {
                        title: this.$tc('toast.success.success'),
                        message: this.$tc('toast.success.campaign-channel-updated')
                    });
                    this.$emit('close');
                })
                .catch(() => {
                    this.$store.dispatch('toast/danger', {
                        title: this.$tc('toast.danger.error'),
                        message: this.$tc('toast.danger.campaign-channel-updated')
                    });
                });
        },
        getCampaignStartingPointClass(sourceType) {
            if (this.pageEditable[this.step] === true) {
                return false;
            }

            if (this.campaignVariablesConfigured) {
                return sourceType === this.campaign.config.configSourceType ? 'selected' : 'grayscale';
            }

            return false;
        },
        getRecipientsSourceClass(sourceType) {
            if (this.pageEditable[this.step] === true) {
                return false;
            }

            if (this.recipientsConfigured) {
                return sourceType === this.recipientConfig.sourceType ? 'selected' : 'grayscale';
            }

            return false;
        },
        validateForm(form, isValid) {
            this.valid[form] = isValid;
        },
        onClickGoBackToRecipients() {
            switch (this.mode.recipients) {
                case null:
                    this.step = 5;
                    break;
                case 'CONTACT':
                    this.$refs.contactRecipients.listContacts();
                    this.$refs.contactRecipients.listSegments();
                    this.step = 6;
                    break;
                case 'SEGMENT':
                    this.$refs.segmentsRecipients.listSegments();
                    this.step = 7;
                    break;
                case 'RULE':
                    this.$refs.ruleRecipients.listRules();
                    this.step = 8;
                    break;
            }
        },
        onClickBack() {
            this.leaveEditMode();

            if (this.step === 5) {
                switch (this.mode.campaign) {
                    case 'NEW_CAMPAIGN':
                        this.step = 2;
                        return
                    case 'EXISTING_CAMPAIGN':
                        this.step = 2;
                        return;
                    case 'PREVIOUS_CAMPAIGN':
                        this.listCampaigns({'genType.gen_type_desc_short': this.campaign.campaignType});
                        this.step = 2;
                        return;
                }
            }

            if ([6, 7, 8].includes(this.step)) {
                this.step = 5;
                return;
            }

            if ([2, 3, 4].includes(this.step)) {
                this.step = 1;
                return;
            }

            if (this.step === 13) {
                this.onClickGoBackToRecipients();
                return;
            }

            if (this.mode.recipients === 'IMPORT' && this.step === 10) {
                this.step = 5;
                return;
            }

            if (this.step === 14 && this.mode.recipients === 'IMPORT') {
                this.$refs.importDataPreview.previewSavedRecipients();
                this.step = 12;
                return;
            }

            if (this.step === 14 && this.mode.recipients === 'MBOX') {
                this.step = 9;
                return;
            }

            if (this.step === 9) {
                this.step = 5;
                return;
            }

            if (this.step === 12 && this.mode.recipients === 'IMPORT') {
                if (this.variablesMapped) {
                    this.$refs.importVariableMapping.initPreSavedMapping();
                } else {
                    this.$refs.importVariableMapping.initMapping();
                }
                this.step = 11;
                return;
            }

            --this.step;
        },
        recipientsCount() {
            switch (this.mode.recipients) {
                case 'CONTACT':
                    return this.selected.contacts.checked.length;
                case 'SEGMENT':
                    return this.contacts.total;
                case 'RULE':
                    return 0
                case 'IMPORT':
                    return this.rows.length;
            }
        },
        async onSelectMode(mode) {
            switch (mode) {
                case 'NEW_CAMPAIGN':
                    this.mode.campaign = mode;
                    this.step = 2;
                    break;
                case 'EXISTING_CAMPAIGN':
                    this.mode.campaign = mode;
                    this.step = 3;
                    break;
                case 'PREVIOUS_CAMPAIGN':
                    this.mode.campaign = mode;
                    this.step = 4;
                    this.listCampaigns({
                        'gen_campaign_unique_id': {
                            'ne': this.campaign.campaignUniqueId
                        },
                        'genType.gen_type_desc_short': this.campaign.campaignType
                    });
                    break;
                case 'MBOX':
                    this.mode.recipients = mode;
                    this.step = 9;
                    break;
                case 'CONTACT':
                    this.mode.recipients = mode;
                    this.step = 6;
                    this.$refs.contactRecipients.listContacts();
                    this.$refs.contactRecipients.listSegments();
                    this.$refs.contactsVariableMapping.initMapping();
                    break;
                case 'SEGMENT':
                    this.mode.recipients = mode;
                    this.step = 7;
                    this.$store.commit('CONTACT/clearContacts');
                    this.$refs.segmentsRecipients.selected = '';
                    this.$refs.segmentsRecipients.listSegments();
                    this.$refs.contactsVariableMapping.initMapping();
                    break;
                case 'RULE':
                    this.mode.recipients = mode;
                    this.step = 8;
                    this.$refs.ruleRecipients.listRules();
                    this.$refs.contactsVariableMapping.initMapping();
                    break;
                case 'IMPORT':
                    this.mode.recipients = mode;
                    this.step = 10;
                    this.$refs.importVariableMapping.initMapping();
                    break;
            }
        },
        getStepClass(step) {
            return {
                active: step === this.step,
                pending: step > this.step,
                complete: step < this.step
            };
        },
        onClickContinue() {
            switch (this.step) {
                case 2:
                    this.step = 5;
                    break;
                case 5:
                    switch (this.mode.recipients) {
                        case 'CONTACT':
                        case 'SEGMENT':
                        case 'RULE':
                        case 'MBOX':
                            this.step = 13;
                            break;
                        case 'IMPORT':
                            this.step = 11;
                            break;
                    }
                    break;
            }
        },
        async onClickSaveAndContinue() {
            this.leaveEditMode();

            if ([2, 3, 4].includes(this.step)) {
                if (this.mode.campaign === 'NEW_CAMPAIGN') {
                    this.$refs.newVariableConfiguration.saveConfiguration();
                }

                if (this.mode.campaign === 'EXISTING_CAMPAIGN') {
                    this.$refs.reuseVariableConfiguration.saveConfiguration();
                }

                if (this.mode.campaign === 'PREVIOUS_CAMPAIGN') {
                    this.$refs.updateVariableConfiguration.saveConfiguration();
                }

                this.$refs.configurationSummary.getRecipientConfig()
                this.$refs.configurationSummary.getRecipients()

                this.step = 5;
                return;
            }

            if (this.step === 5) {
                switch (this.mode.recipients) {
                    case 'CONTACT':
                        this.step = 6;
                        return;
                    case 'SEGMENT':
                        this.step = 7;
                        return;
                    case 'RULE':
                        this.step = 8;
                        return;
                    case 'IMPORT':
                        this.step = 11;
                        return;
                    case 'MBOX':
                        this.step = 9;
                        return;
                }
            }

            if (this.step === 6) {
                this.$refs.contactRecipients.saveRecipientsConfig();
                this.step = 13;
                return;
            }

            if (this.step === 7) {
                this.$refs.segmentsRecipients.saveRecipientsConfig();
                this.step = 13;
                return;
            }

            if (this.step === 10) {
                this.$refs.importRecipients.onSendCsv();
            }

            if (this.step === 11) {
                this.$refs.importVariableMapping.saveConfiguration();
            }

            if (this.step === 13) {
                this.$refs.contactsVariableMapping.saveConfiguration();
                this.$refs.configurationSummary.getRecipientConfig()
                this.$refs.configurationSummary.getRecipients()
                this.step = 14;
                return;
            }

            if (this.step === 8) {
                this.$refs.ruleRecipients.saveRecipientsConfig();
                this.step = 13;
                return;
            }

            if (this.step === 12) {
                this.$refs.configurationSummary.getRecipientConfig()
                this.$refs.configurationSummary.getRecipients()
                this.step = 14;
                return;
            }

            if (this.step === 9) {
                this.step = 14;
                return;
            }

            this.step += 1;
        },
        setProcessJobUniqueId(processJobUniqueId) {
            this.processJobUniqueId = processJobUniqueId;
        },
        setCsvVariables(variables) {
            this.csvVariables = variables;
        },
        checkConfigurationStatus() {
            if (this.recipientsConfigured && this.campaignVariablesConfigured) {
                this.mode.recipients = this.recipientConfig.sourceType;
            }

            if (this.campaignVariablesConfigured) {
                this.mode.campaign = this.campaign.config.configSourceType;
            }

            if (this.configExists) {
                this.step = 14;
                return;
            }

            if (this.campaignSent || (this.variablesMapped && this.campaignVariablesConfigured)) {
                if (this.campaign.campaignType === 'CAMPAIGN_MBOX') {
                    this.mode.recipients = 'MBOX';
                }
                this.step = 14;
                return
            }

            if (this.recipientsConfigured && this.campaignVariablesConfigured) {
                if (this.mode.recipients === 'IMPORT') {
                    this.step = 11;
                } else {
                    this.step = 13;
                }
                return;
            }

            if (this.campaignVariablesConfigured) {
                this.step = 5;
            }
        },

    },
    watch: {
        campaign(campaign) {
            if (!this.configExists) {
                this.campaignName = campaign.campaignDescShort;
            }
            if (this.configExists) {
                this.mode.campaign = this.campaign.config.configSourceType;
                this.campaignName = this.campaign.config.campaignData.campaignName;
            }
        },
        step(step) {
            if (step === 14 && this.variablesMapped && this.recipientsConfigured) {
                this.$refs.contactsVariableMapping.deconstructMapping();
            }

            switch (step) {
                case 2:
                    this.$refs.newVariableConfiguration.checkFormValidation();
                    return;
                case 3:
                    this.$refs.reuseVariableConfiguration.checkFormValidation();
                    return;
                case 4:
                    this.$refs.updateVariableConfiguration.checkFormValidation();
                    return;
                case 6:
                    return;
                case 7:
                    this.$refs.segmentsRecipients.checkFormValidation();
                    return;
                case 8:
                    this.$refs.ruleRecipients.checkFormValidation();
                    return;
                case 10:
                    this.$refs.importRecipients.checkFormValidation();
                    return;
                case 11:
                    this.$refs.importVariableMapping.checkFormValidation();
                    return;
                case 12:
                    this.$refs.importDataPreview.checkFormValidation();
                    return;
                case 13:
                    this.$refs.contactsVariableMapping.checkFormValidation();
                    return;
                case 14:
                    this.$refs.configurationSummary.checkFormValidation();
                    return;
            }
        }
    },
    beforeMount() {
        this.checkConfigurationStatus();
    }
}
</script>

<style lang="scss" scoped>
.modal-wrapper {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;

    .step {
        transition: margin .3s ease-in-out;
        position: absolute;
        top: 7.7rem;
        right: 0;
        left: 0;
        bottom: 0;
        width: 100%;

        &.active {
            margin-left: 0;
        }

        &.complete {
            margin-left: -100%;
            overflow: hidden;
        }

        &.pending {
            margin-left: 100%;
            overflow: hidden;
        }
    }

    .selected {
        z-index: 1;
        color: #333333;
        text-decoration: none;
        background-color: #f6f8ef;
        border: 1px solid #708238 !important;
        pointer-events: none;
    }

    .grayscale {
        filter: grayscale(100%) !important;
        pointer-events: none;

        &:hover {
            background-color: transparent;
        }
    }
}
</style>
