import { render, staticRenderFns } from "./ComponentConfigurationSummary.vue?vue&type=template&id=38e2ac7c&scoped=true&"
import script from "./ComponentConfigurationSummary.vue?vue&type=script&lang=js&"
export * from "./ComponentConfigurationSummary.vue?vue&type=script&lang=js&"
import style0 from "./ComponentConfigurationSummary.vue?vue&type=style&index=0&id=38e2ac7c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38e2ac7c",
  null
  
)

export default component.exports