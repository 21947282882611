<template>
    <div class="step px-3">
        <div v-if="!loading.SEGMENT_LIST" class="row">
            <div v-if="segments.list.length > 0" class="col-12">
                <div class="mb-3">
                    <label class="form-label" for="segment"><strong>{{ $tc('segment', 2) }}</strong></label>
                    <select id="segment"
                            v-model="selected"
                            :aria-label="$tc('segment', 2)"
                            :class="{ 'is-invalid': this.$v.selected.$invalid }"
                            class="form-select"
                            @change="listContacts($event.target.value)">
                        <option disabled value="">-- {{ $t('actions.please-select') }} --</option>
                        <option v-for="(segment, i) in segments.list"
                                :key="segment.segmentUniqueId"
                                :value="i">
                            {{ segment.segmentDescShort }}
                        </option>
                    </select>
                    <div v-if="!this.$v.selected.required" class="invalid-feedback">
                        {{ $t('validation.error.required.segment') }}
                    </div>
                </div>
            </div>
            <div v-if="segments.list.length > 0" class="col-12">
                <div class="alert alert-info d-flex align-items-center">
                    <font-awesome-icon class="me-3" icon="info-circle" size="2x"/>
                    {{ $t('modal.recipients-and-campaigns-configuration.contacts-in-segment-count', {count: contacts.list.length}) }}
                </div>
                <div class="table-responsive">
                    <table class="table table-sm table-bordered">
                        <thead>
                        <tr>
                            <th class="bg-light" scope="col"><strong>{{ $tc('salutation', 1) }}</strong></th>
                            <th class="bg-light" scope="col"><strong>{{ $tc('first-name', 1) }}</strong></th>
                            <th class="bg-light" scope="col"><strong>{{ $tc('last-name', 1) }}</strong></th>
                            <th class="bg-light" scope="col"><strong>{{ $tc('company', 1) }}</strong></th>
                            <th class="bg-light" scope="col"><strong>{{ $tc('street', 1) }}</strong></th>
                            <th class="bg-light" scope="col"><strong>{{ $tc('postcode', 1) }}</strong></th>
                            <th class="bg-light" scope="col"><strong>{{ $tc('city', 1) }}</strong></th>
                            <th class="bg-light" scope="col"><strong>{{ $tc('country', 1) }}</strong></th>
                            <th class="bg-light" scope="col"><strong>{{ $tc('email', 1) }}</strong></th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-cloak v-if="!loading.CONTACT_LIST && contacts.list.length > 0">
                            <tr v-for="(contact, i) in contacts.list.slice(0, 20)" :key="'contact-' + i">
                                <td>{{ contact.address.salutation }}</td>
                                <td>{{ contact.address.firstName }}</td>
                                <td>{{ contact.address.lastName }}</td>
                                <td>{{ contact.address.company }}</td>
                                <td>{{ contact.address.street }}</td>
                                <td>{{ contact.address.postcode }}</td>
                                <td>{{ contact.address.city }}</td>
                                <td>
                                    <template v-if="contact.address.country">
                                        <span>{{ countries[contact.address.country].name }}</span>
                                    </template>
                                </td>
                                <td>{{ contact.contactInformation.email }}</td>
                            </tr>
                        </template>
                        <template v-cloak v-if="!loading.CONTACT_LIST && contacts.list.length === 0">
                            <tr>
                                <td colspan="99">
                                    <div
                                        class="py-3 text-primary d-flex align-items-center justify-content-center flex-column mb-0"
                                        role="alert">
                                        <font-awesome-icon icon="exclamation-triangle" size="3x"/>
                                        <div class="mt-2 fs-4">{{ $t('pages.contacts.no-contacts-found') }}</div>
                                    </div>
                                </td>
                            </tr>
                        </template>
                        <template v-if="loading.CONTACT_LIST">
                            <tr v-for="n in 10" :key="'row-shimmer-' + n">
                                <td class="align-middle">
                                    <component-shimmer height="14px" style="margin: 4px 0;" width="14px"/>
                                </td>
                                <td class="align-middle"><component-shimmer width="100%"/></td>
                                <td class="align-middle"><component-shimmer width="100%"/></td>
                                <td class="align-middle"><component-shimmer width="100%"/></td>
                                <td class="align-middle"><component-shimmer width="100%"/></td>
                                <td class="align-middle"><component-shimmer width="100%"/></td>
                                <td class="align-middle"><component-shimmer width="100%"/></td>
                                <td class="align-middle"><component-shimmer width="100%"/></td>
                                <td class="align-middle"><component-shimmer width="100%"/></td>
                                <td class="align-middle"><component-shimmer width="100%"/></td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </div>
            </div>
            <div v-if="segments.list.length === 0" class="col-12">
                <p>{{ $t('modal.recipients-and-campaigns-configuration.no-segments-found') }}</p>
                <p>
                    <button class="btn btn-primary" type="button" @click="onClickCreateSegment">
                        {{ $t('actions.create-segment') }}
                    </button>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import ComponentShimmer from "../../../components/ComponentShimmer";
import closeModal from "../../../lib/close-modal";
import {countries} from "countries-list";
import {required} from "vuelidate/lib/validators";

export default {
    name: "ComponentSegmentsRecipients",
    components: {ComponentShimmer},
    computed: {
        v() {
            return this.$v;
        },
        formValid() {
            return !this.$v.$invalid;
        },
        loading() {
            return {
                SEGMENT_LIST: this.$store.state.loading.SEGMENT_LIST,
                CONTACT_LIST: this.$store.state.loading.CONTACT_LIST,
            }
        },
        segments() {
            return this.$store.state.SEGMENT.segments;
        },
        contacts() {
            return this.$store.state.CONTACT.contacts;
        },
        campaign() {
            return this.$store.state.CAMPAIGN.campaign;
        },
        recipientConfig() {
            return this.$store.state.CAMPAIGN.recipientConfig;
        },
        versioning() {
            let version = this.campaign.config.versioning;
            version.recipients = this.campaign.config.versioning.recipients + 1;
            return version;
        },
    },
    props: {
        recipientsConfigured: {
            Type: Boolean,
            required: true
        },
        pageEditable: {
            Type: Boolean,
            required: false
        }
    },
    data() {
        return {
            selected: '',
            countries
        }
    },
    methods: {
        listSegments() {
            this.$store.dispatch('SEGMENT/LIST', {
                filter: {},
                order: {meta_insert_dt: 'DESC'},
                page: 1,
                itemsPerPage: null,
                limit: null
            });
        },
        listContacts(segmentIndex) {
            this.$store.dispatch('CONTACT/LIST', {
                filter: {
                    "genSegmentContactRels.genSegment.gen_segment_unique_id": this.segments.list[segmentIndex].segmentUniqueId
                },
                order: {meta_insert_dt: "DESC"},
                page: 1,
                itemsPerPage: null,
            })
        },
        saveRecipientsConfig() {
            this.$store.dispatch('CAMPAIGN/SAVE_RECIPIENT_CONFIG', {
                campaignUniqueId: this.campaign.campaignUniqueId,
                sourceType: 'SEGMENT',
                sourceName: this.segments.list[this.selected].segmentDescShort,
                contactUniqueIds: [],
                segmentUniqueIds: [this.segments.list[this.selected].segmentUniqueId],
                ruleUniqueId: null,
                importConfig: {
                    mediaObjectUniqueId: null,
                    processJobUniqueId: null,
                    workflowUniqueId: null
                }
            })
                .then(() => {
                    this.$store.dispatch('CAMPAIGN/GET_RECIPIENT_CONFIG', this.campaign.campaignUniqueId);
                });

            this.$store.dispatch('CAMPAIGN/UPDATE_CHANNEL', {
                campaignUniqueId: this.campaign.campaignUniqueId,
                channelUniqueId: this.campaign.outboundChannels[0].channelUniqueId,
                saveExternalCampaign: false,
                configData: {
                    versioning: this.versioning,
                    campaignData: {
                        campaignName: this.campaign.config ? this.campaign.config.campaignData.campaignName : null,
                        startDate: '2020-01-01',
                        endDate: null
                    },
                    printNodes: [
                        {
                            printNodeId: 'Print_Node_01',
                            description: 'Print_Node_01'
                        }
                    ],
                    variables: this.campaign.config ? this.campaign.config.variables : null,
                    staticValues: this.campaign.config ? this.campaign.config.staticValues : null,
                    sourceColumns: this.campaign.config ? this.campaign.config.sourceColumns : null,
                    recipientMapping: this.campaign.config ? this.campaign.config.recipientMapping : null,
                    configSourceType: this.campaign.config ? this.campaign.config.configSourceType : null,
                    printMailingAutomationCampaignUniqueId: this.campaign.config
                        ? this.campaign.config.printMailingAutomationCampaignUniqueId
                        : null
                }
            })
                .catch(() => {
                    this.$store.dispatch('toast/danger', {
                        title: this.$tc('toast.danger.error'),
                        message: this.$tc('toast.danger.campaign-channel-updated')
                    });
                });
        },
        onClickCreateSegment() {
            closeModal(this.$refs.recipientsAndCampaignConfiguration.$el);
            this.$router.push({name: 'marketing-segments'});
        },
        checkFormValidation() {
            this.$emit('validateForm', 'segmentsRecipients', this.formValid)
        }
    },
    watch: {
        formValid() {
            this.checkFormValidation();
        },
        segments() {
            if (this.recipientsConfigured) {
                if (this.recipientConfig.sourceType === 'SEGMENT') {
                    this.segments.list.forEach((segment, i) => {
                        if (this.recipientConfig.segmentUniqueIds.join() === segment.segmentUniqueId) {
                            this.selected = i;
                        }
                    })
                }
            }
        }
    },
    validations: {
        selected: {
            required
        }
    },
    beforeMount() {
        if (this.recipientsConfigured) {
            if (this.recipientConfig.sourceType === 'SEGMENT') {
                this.segments.list.forEach((segment, i) => {
                    if (this.recipientConfig.segmentUniqueIds.join() === segment.segmentUniqueId) {
                        this.selected = i;
                    }
                })
            }
        }
    }
}
</script>
