<template>
    <layout-full-width :status="$t('statuses.campaign.' + campaign.campaignStatus)"
                       :status-bg="getCampaignStatusClass(campaign.campaignStatus)"
                       :title="campaign.campaignDescShort">
        <div class="row">
            <div v-if="campaign && campaign.campaignStatus === 'CAMPAIGN_COMPLETED'" class="col-12">
                <div class="alert alert-info" v-html="$t('alerts.info.campaign-is-submitted')"/>
            </div>

            <div class="col-12">
                <div v-if="!loading.CAMPAIGN_GET && !loading.CAMPAIGN_UPDATE"
                     class="card mb-3">
                    <!-- OUTBOUND MARKETING CHANNEL -->
                    <div class="card-body d-flex justify-content-between border-bottom">
                        <div class="me-3">
                            <font-awesome-icon class="text-success" icon="check-circle" size="3x"/>
                        </div>
                        <div class="flex-grow-1">
                            <h2 class="mt-0 h5">{{ $t('outbound-marketing-channel') }}</h2>
                            <p>{{ $t('pages.campaign-settings.outbound-marketing-channel') }}</p>

                            <strong class="me-1">{{ $tc('name', 1) }}:</strong>
                            <span>{{ campaign.outboundChannels[0].channelDescShort }}</span>
                            <br>

                            <strong class="me-1">{{ $tc('service', 1) }}:</strong>
                            <span>
                                {{ campaign.outboundChannels[0].serviceProviderService.serviceProvider.serviceProviderDescLong }}
                                &middot;
                                {{ campaign.outboundChannels[0].serviceProviderService.serviceProviderServiceDescLong }}
                                <template v-if="campaign.outboundChannels[0].config.printMailingAutomationUrl">
                                    -
                                    <a :href="campaign.outboundChannels[0].config.printMailingAutomationUrl"
                                       rel="noopener noreferrer"
                                       target="_blank">
                                        <font-awesome-icon icon="external-link-alt"/>
                                        {{ $t('open-deutsche-post-print-mailing-automation-dashboard') }}
                                    </a>
                                </template>
                            </span>
                        </div>
                    </div>

                    <!-- INBOUND MARKETING CHANNEL -->
                    <div class="card-body d-flex justify-content-between border-bottom">
                        <div class="me-3">
                            <font-awesome-icon class="text-success" icon="check-circle" size="3x"/>
                        </div>
                        <div class="flex-grow-1">
                            <h2 class="mt-0 h5">{{ $t('inbound-marketing-channel') }}</h2>
                            <p>{{ $t('pages.campaign-settings.inbound-marketing-channel') }}</p>

                            <strong class="me-1">{{ $tc('name', 1) }}:</strong>
                            <span>{{ campaign.inboundChannels[0].channelDescShort }}</span>
                            <br>

                            <strong class="me-1">{{ $tc('marketing-channel', 1) }}:</strong>
                            <span>
                                {{ campaign.inboundChannels[0].serviceProviderService.serviceProvider.serviceProviderDescLong }}
                                &middot;
                                {{ campaign.inboundChannels[0].serviceProviderService.serviceProviderServiceDescLong }}
                            </span>
                        </div>
                    </div>

                    <!-- CAMPAIGN DETAILS -->
                    <div class="card-body d-flex justify-content-between border-bottom">
                        <div class="me-3">
                            <font-awesome-icon v-show="campaignFullyConfigured"
                                               class="text-success"
                                               icon="check-circle"
                                               size="3x"/>
                            <font-awesome-icon v-show="!campaignFullyConfigured"
                                               class="text-warning"
                                               icon="exclamation-circle"
                                               size="3x"/>
                        </div>
                        <div class="flex-grow-1">
                            <h2 class="mt-0 h5">{{ $t('pages.campaign-settings.campaign-recipients-variable-configuration') }}</h2>
                            <p class="mb-0">{{ $t('pages.campaign-settings.campaign-details') }}</p>
                        </div>

                        <div class="float-right">
                            <button :disabled="isConfigurationDisabled(campaign)"
                                    class="btn btn-primary m-0"
                                    data-bs-target="#campaign-recipients-variable-configuration"
                                    data-bs-toggle="modal"
                                    @click="componentCampaignSettingsOpen = true;">
                                {{ $t('actions.configure') }}
                            </button>
                        </div>
                    </div>

                    <!-- ADVANCED OPTIONS -->
                    <div class="card-body d-flex justify-content-between border-bottom">
                        <div class="me-3">
                            <font-awesome-icon class="text-success" icon="check-circle" size="3x"/>
                        </div>
                        <div class="flex-grow-1">
                            <h2 class="mt-0 h5">{{ $tc('advanced-option', 2) }}</h2>
                            <p class="mb-0">{{ $t('pages.campaign-settings.advanced-options') }}</p>
                        </div>

                        <div class="float-right">
                            <button :disabled="isConfigurationDisabled(campaign)"
                                    class="btn btn-primary m-0"
                                    data-bs-target="#modal-create-processing-options"
                                    data-bs-toggle="modal">
                                {{ $t('actions.configure') }}
                            </button>
                        </div>
                    </div>

                    <!-- CONFIRMATION (REGULAR) -->
                    <div v-if="canShowRegularCampaignFinalization"
                         class="card-body d-flex justify-content-between">
                        <div class="me-3">
                            <font-awesome-icon v-show="canSendCampaign"
                                               class="text-success"
                                               icon="check-circle"
                                               size="3x"/>
                            <font-awesome-icon v-show="!canSendCampaign"
                                               class="text-warning"
                                               icon="exclamation-circle"
                                               size="3x"/>
                        </div>
                        <div class="flex-grow-1">
                            <h2 class="mt-0 h5">{{ $tc('finalization', 1) }}</h2>
                            <p>{{ $t('pages.campaign-settings.confirmation') }}</p>

                            <div class="form-check mb-3">
                                <input id="campaign-config"
                                       v-model="confirmation.configured"
                                       class="form-check-input"
                                       type="checkbox">
                                <label class="form-check-label"
                                       for="campaign-config"
                                       v-html="$t('checkboxes.configuration.campaign', { url: campaign.outboundChannels[0].config.printMailingAutomationUrl })"></label>
                            </div>

                            <button :disabled="!canSendCampaign"
                                    class="btn btn-primary"
                                    @click="triggerCampaign">
                                <template v-if="campaign.campaignType === 'CAMPAIGN_REGULAR'">
                                    {{ $t('actions.start-campaign') }}
                                </template>
                                <template v-if="campaign.campaignType === 'CAMPAIGN_MBOX'">
                                    {{ $t('actions.activate-campaign') }}
                                </template>
                            </button>
                        </div>
                    </div>

                    <!-- CONFIRMATION (AUTOMATED_EVENT_BASED) -->
                    <div v-if="campaign.campaignType === 'CAMPAIGN_AUTOMATED_EVENT_BASED'"
                         class="card-body d-flex justify-content-between">
                        <template v-if="campaign.campaignStatus === 'CAMPAIGN_DRAFT'">
                            <div class="me-3">
                                <font-awesome-icon v-show="canActivateCampaign"
                                                   class="text-success"
                                                   icon="check-circle"
                                                   size="3x"/>
                                <font-awesome-icon v-show="!canActivateCampaign"
                                                   class="text-warning"
                                                   icon="exclamation-circle"
                                                   size="3x"/>
                            </div>
                            <div class="flex-grow-1">
                                <h2 class="mt-0 h5">{{ $tc('finalization', 1) }}</h2>
                                <p>{{ $t('pages.campaign-settings.confirmation') }}</p>

                                <div class="form-check mb-3">
                                    <input id="campaign-config-automated"
                                           v-model="confirmation.configured"
                                           class="form-check-input"
                                           type="checkbox">
                                    <label class="form-check-label"
                                           for="campaign-config-automated"
                                           v-html="$t('checkboxes.configuration.campaign', { url: campaign.outboundChannels[0].config.printMailingAutomationUrl })"></label>
                                </div>

                                <button :disabled="!canActivateCampaign"
                                        class="btn btn-primary"
                                        type="submit"
                                        @click="onChangeCampaignStatus('CAMPAIGN_ACTIVE')">
                                    {{ $t('actions.activate-campaign') }}
                                </button>
                            </div>
                        </template>

                        <template v-if="campaign.campaignStatus === 'CAMPAIGN_ACTIVE'">
                            <div class="me-3">
                                <font-awesome-icon class="text-success"
                                                   icon="check-circle"
                                                   size="3x"/>
                            </div>
                            <div class="flex-grow-1">
                                <h2 class="mt-0 h5">{{ $tc('finalization', 1) }}</h2>
                                <p>{{ $t('pages.campaign-settings.campaign-is-active') }}</p>

                                <button :disabled="!canDeactivateCampaign"
                                        class="btn btn-primary"
                                        type="submit"
                                        @click="onChangeCampaignStatus('CAMPAIGN_DRAFT')">
                                    {{ $t('actions.deactivate-campaign') }}
                                </button>
                            </div>
                        </template>
                    </div>

                    <!-- CONFIRMATION (MBOX) -->
                    <div v-if="campaign.campaignType === 'CAMPAIGN_MBOX'"
                         class="card-body d-flex justify-content-between">
                        <template v-if="campaign.campaignStatus === 'CAMPAIGN_DRAFT'">
                            <div class="me-3">
                                <font-awesome-icon v-show="canActivateCampaign"
                                                   class="text-success"
                                                   icon="check-circle"
                                                   size="3x"/>
                                <font-awesome-icon v-show="!canActivateCampaign"
                                                   class="text-warning"
                                                   icon="exclamation-circle"
                                                   size="3x"/>
                            </div>
                            <div class="flex-grow-1">
                                <h2 class="mt-0 h5">{{ $tc('finalization', 1) }}</h2>
                                <p>{{ $t('pages.campaign-settings.confirmation') }}</p>

                                <div class="form-check mb-3">
                                    <input id="campaign-config-mbox"
                                           v-model="confirmation.configured"
                                           class="form-check-input"
                                           type="checkbox">
                                    <label class="form-check-label"
                                           for="campaign-config-mbox"
                                           v-html="$t('checkboxes.configuration.campaign', { url: campaign.outboundChannels[0].config.printMailingAutomationUrl })"></label>
                                </div>

                                <button :disabled="!canActivateCampaign"
                                        class="btn btn-primary"
                                        type="submit"
                                        @click="onChangeCampaignStatus('CAMPAIGN_ACTIVE')">
                                    {{ $t('actions.activate-campaign') }}
                                </button>
                            </div>
                        </template>

                        <template v-if="campaign.campaignStatus === 'CAMPAIGN_ACTIVE'">
                            <div class="me-3">
                                <font-awesome-icon class="text-success"
                                                   icon="check-circle"
                                                   size="3x"/>
                            </div>
                            <div class="flex-grow-1">
                                <h2 class="mt-0 h5">{{ $tc('finalization', 1) }}</h2>
                                <p>{{ $t('pages.campaign-settings.campaign-is-active') }}</p>

                                <button :disabled="!canDeactivateCampaign"
                                        class="btn btn-primary"
                                        type="submit"
                                        @click="onChangeCampaignStatus('CAMPAIGN_DRAFT')">
                                    {{ $t('actions.deactivate-campaign') }}
                                </button>
                            </div>
                        </template>
                    </div>
                </div>

                <template v-else>
                    <div class="card mb-3">
                        <div class="card-body d-flex justify-content-between border-bottom">
                            <div class="me-3">
                                <component-shimmer class="rounded-circle" height="3em" width="3em"/>
                            </div>
                            <div class="flex-grow-1">
                                <component-shimmer class="mb-2" height="1.2rem" width="30ch"/>
                                <component-shimmer class="mb-3" height="1.2rem"/>
                                <component-shimmer height="1.2rem" width="30ch"/>
                                <component-shimmer height="1.2rem" width="30ch"/>
                            </div>
                        </div>
                        <div class="card-body d-flex justify-content-between border-bottom">
                            <div class="me-3">
                                <component-shimmer class="rounded-circle" height="3em" width="3em"/>
                            </div>
                            <div class="flex-grow-1">
                                <component-shimmer class="mb-2" height="1.2rem" width="30ch"/>
                                <component-shimmer class="mb-3" height="1.2rem"/>
                                <component-shimmer height="1.2rem" width="30ch"/>
                                <component-shimmer height="1.2rem" width="30ch"/>
                            </div>
                        </div>
                        <div class="card-body d-flex justify-content-between border-bottom">
                            <div class="me-3">
                                <component-shimmer class="rounded-circle" height="3em" width="3em"/>
                            </div>
                            <div class="flex-grow-1">
                                <component-shimmer class="mb-2" height="1.2rem" width="30ch"/>
                                <component-shimmer height="1.2rem"/>
                            </div>
                        </div>
                        <div class="card-body d-flex justify-content-between">
                            <div class="me-3">
                                <component-shimmer class="rounded-circle" height="3em" width="3em"/>
                            </div>
                            <div class="flex-grow-1">
                                <component-shimmer class="mb-2" height="1.2rem" width="30ch"/>
                                <component-shimmer class="mb-3" height="1.2rem"/>
                                <component-shimmer class="mb-3" height="1.2rem" width="80%"/>
                                <component-shimmer height="33.2px" width="118.317px"/>
                            </div>
                        </div>
                    </div>

                    <div class="card bg-light border-light">
                        <div class="card-body">
                            <component-shimmer class="mb-2" height="1.2rem"/>
                            <component-shimmer class="mb-3"/>
                            <component-shimmer/>
                        </div>
                    </div>
                </template>

                <!-- TERMS OF USE -->
                <div v-if="!loading.CAMPAIGN_GET" class="card bg-light border-light text-dark">
                    <div class="card-body">
                        <h2 class="h5">{{ $t('terms-of-use') }}</h2>

                        <p v-html="$t('checkboxes.terms-of-use.stina', { urlTermsAndConditions: links.stinaTermsAndConditionsUrl, urlPrivacyPolicy: links.stinaPrivacyPolicyUrl })"></p>
                        <p v-if="campaign.outboundChannels[0].serviceProviderService.serviceProvider.serviceProviderDescShort === 'DEUTSCHE_POST'"
                           class="mb-0"
                           v-html="$t('checkboxes.terms-of-use.deutsche-post', { urlTermsAndConditions: links.deutschePostTermsAndConditions })"></p>
                    </div>
                </div>
            </div>
        </div>

        <!-- ADVANCED OPTIONS MODAL -->
        <component-modal id="modal-create-processing-options"
                         size="modal-lg"
                         title="Spot Emotion - Advanced Options">
            <form>
                <div class="modal-body">
                    <template>
                        <!-- Automated processing -->
                        <label class="form-label fw-bold" for="workflow-select">
                            {{ $tc('execution-plan', 1) }}
                        </label>
                        <select id="workflow-select" class="form-select">
                            <option v-if="automatableCampaignTypes.includes(campaign.campaignType)" value="AUTOMATED">
                                {{ $t('automated-processing') }}
                            </option>
                            <option v-else value="IMMEDIATE">
                                {{ $tc('immediate-processing') }}
                            </option>
                        </select>

                        <label class="form-label fw-bold mt-3" for="data-quality-rules">
                            {{ $t('pages.campaign-settings.data-quality-rules') }}
                        </label>
                        <select id="data-quality-rules" class="form-select">
                            <option value="DATA_QUALITY_RULE_BASIC">{{ $t('pages.campaign-settings.basic') }}</option>
                        </select>

                        <label class="form-label fw-bold mt-3" for="duplicate-check">
                            {{ $t('pages.campaign-settings.duplicate-check') }}
                        </label>
                        <select id="duplicate-check" class="form-select">
                            <option value="CAMPAIGN_LEVEL">{{ $t('pages.campaign-settings.campaign-level') }}</option>
                        </select>

                        <label class="form-label fw-bold mt-3" for="notifications">
                            {{ $tc('notification', 2) }}
                        </label>
                        <select id="notifications" class="form-select">
                            <option value="">{{ $t('none') }}</option>
                        </select>
                    </template>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-primary" type="submit">
                        {{ $t('actions.save') }}
                    </button>
                </div>
            </form>
        </component-modal>

        <!-- RECIPIENTS AND CAMPAIGN CONFIGURATION WIZARD  -->
        <component-modal id="campaign-recipients-variable-configuration"
                         ref="recipientsAndCampaignConfiguration"
                         size="modal-xxl"
                         title="Spot Emotion - Recipients and Campaign Details Configuration">
            <component-campaign-settings v-if="componentCampaignSettingsOpen"
                                         ref="componentCampaignSettings"
                                         :campaignType="campaign.campaignType"
                                         @close="closeCampaignSettingsModal"
                                         @getCampaign="loadCampaign"/>
        </component-modal>

    </layout-full-width>
</template>

<script>
import LayoutFullWidth from "../../layouts/LayoutFullWidth";
import ComponentModal from '../../components/ui/ComponentModal.vue';
import ComponentShimmer from "../../components/ComponentShimmer";
import ComponentCampaignSettings from "../../components/campaign-settings/ComponentCampaignSettings";
import Tooltip from "bootstrap/js/src/tooltip";
import closeModal from "../../lib/close-modal";
import {deutschePostTermsAndConditions, stinaPrivacyPolicyUrl, stinaTermsAndConditionsUrl} from "../../lib/links";

export default {
    name: "PageCampaignSettings",
    components: {
        ComponentShimmer,
        LayoutFullWidth,
        ComponentModal,
        ComponentCampaignSettings
    },
    computed: {
        loading() {
            return {
                CAMPAIGN_UPDATE: this.$store.state.loading.CAMPAIGN_UPDATE,
                CAMPAIGN_GET: this.$store.state.loading.CAMPAIGN_GET,
                CAMPAIGN_UPDATE_CHANNEL: this.$store.state.loading.CAMPAIGN_UPDATE_CHANNEL
            }
        },
        campaign() {
            return this.$store.state.CAMPAIGN.campaign;
        },
        campaignConfigExists() {
            const config = this.$store.state.CAMPAIGN.campaign.config;

            if (config === null) {
                return false;
            }

            return config.campaignData !== null;
        },
        campaignFullyConfigured() {
            return this.campaign.config
                && this.campaign.config.configStatus === 'CAMPAIGN_CHANNEL_CONFIG_FULLY_CONFIGURED';
        },
        canSendCampaign() {
            return !(
                !this.campaignConfigExists
                || !this.confirmation.configured
                || this.campaign.campaignStatus !== 'CAMPAIGN_DRAFT'
            );
        },
        canActivateCampaign() {
            return !(
                !this.campaignConfigExists
                || !this.confirmation.configured
                || this.campaign.campaignStatus !== 'CAMPAIGN_DRAFT'
            );
        },
        canDeactivateCampaign() {
            return !(
                !this.campaignConfigExists
                || this.campaign.campaignStatus !== 'CAMPAIGN_ACTIVE'
            );
        },
        automatableCampaignTypes() {
            return ['CAMPAIGN_AUTOMATED_RULE_BASED', 'CAMPAIGN_AUTOMATED_EVENT_BASED', 'CAMPAIGN_MBOX'];
        },
        canShowRegularCampaignFinalization() {
            return this.campaign.campaignType === 'CAMPAIGN_REGULAR'
                && this.campaign.campaignStatus === 'CAMPAIGN_DRAFT'
        }
    },
    data() {
        return {
            links: {
                stinaTermsAndConditionsUrl,
                stinaPrivacyPolicyUrl,
                deutschePostTermsAndConditions
            },
            campaignConfigured: false,
            componentCampaignSettingsOpen: false,
            confirmation: {
                configured: false
            },
            recipients: {
                list: []
            }
        }
    },
    methods: {
        tooltips() {
            document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(tooltip => new Tooltip(tooltip));
        },
        loadCampaign() {
            this.$store.dispatch('CAMPAIGN/GET_RECIPIENT_CONFIG', this.$route.params.id).catch(() => {});
            this.$store.dispatch('CAMPAIGN/GET', this.$route.params.id);
        },
        listMediaLibrary() {
            this.$store.dispatch('MEDIA_LIBRARY/LIST', {
                filter: {},
                order: {meta_insert_dt: 'DESC'},
                page: 1,
                itemsPerPage: 25,
                limit: null,
            });
        },
        getRecipients() {
            this.$store.dispatch('CAMPAIGN/PREVIEW_RECIPIENT', {
                campaignUniqueId: this.$route.params.id,
                limit: 1
            }).catch(() => {});
        },
        onImportContacts() {
            this.$store.dispatch('toast/success', {
                title: this.$tc('toast.success.success'),
                message: this.$tc('toast.success.import-contacts')
            });
        },
        onChangeCampaignStatus(campaignStatus) {
            this.$store.dispatch('CAMPAIGN/UPDATE', {
                campaignUniqueId: this.campaign.campaignUniqueId,
                campaignDescShort: this.campaign.campaignDescShort,
                campaignDescLong: this.campaign.campaignDescLong,
                campaignComment: this.campaign.campaignComment,
                campaignType: this.campaign.campaignType,
                campaignStatus,
                mboxEmail: this.campaign.mboxEmail,
                inboundChannels: [{channelUniqueId: this.campaign.inboundChannels[0].channelUniqueId}],
                outboundChannels: [{channelUniqueId: this.campaign.outboundChannels[0].channelUniqueId}],
            })
                .then(() => {
                    if (campaignStatus === 'CAMPAIGN_ACTIVE') {
                        this.$store.dispatch('toast/success', {
                            title: this.$tc('toast.success.success'),
                            message: this.$tc('toast.success.campaign-activated')
                        });
                    } else if (campaignStatus === 'CAMPAIGN_DRAFT') {
                        this.$store.dispatch('toast/success', {
                            title: this.$tc('toast.success.success'),
                            message: this.$tc('toast.success.campaign-deactivated')
                        });
                    }
                })
                .catch(error => {
                    this.$store.dispatch('toast/danger', {
                        title: this.$tc('toast.danger.error'),
                        message: error.response.data.message
                    });
                });
        },
        triggerCampaign() {
            this.$store.dispatch('EVENT/TRIGGER_CAMPAIGN', {
                campaignUniqueId: this.campaign.campaignUniqueId,
                channelUniqueId: this.campaign.outboundChannels[0].channelUniqueId
            })
                .then(() => {
                    this.$store.dispatch('toast/success', {
                        title: this.$tc('toast.success.success'),
                        message: this.$tc('toast.success.send-campaign')
                    });
                    this.$router.push({name: 'marketing-campaign-log', params: {id: this.campaign.campaignUniqueId}});
                    this.$gtag.event('EVENT_TRIGGER_CAMPAIGN', {
                        CAMPAIGN_UNIQUE_ID: this.campaign.campaignUniqueId
                    });
                })
                .catch(() => {
                    this.$store.dispatch('toast/danger', {
                        title: this.$tc('toast.danger.error'),
                        message: this.$tc('toast.danger.send-campaign')
                    });
                });
        },
        closeCampaignSettingsModal() {
            closeModal(this.$refs.recipientsAndCampaignConfiguration.$el);
            this.componentCampaignSettingsOpen = false;
            this.$forceUpdate();
        },
        getCampaignStatusClass(campaignStatus) {
            return {
                'bg-info': campaignStatus === 'CAMPAIGN_ACTIVE',
                'bg-dark': campaignStatus === 'CAMPAIGN_PAUSED',
                'bg-light': campaignStatus === 'CAMPAIGN_DRAFT',
                'bg-success': campaignStatus === 'CAMPAIGN_COMPLETED',
                'bg-secondary': campaignStatus === 'CAMPAIGN_ARCHIVED'
            }
        },
        isConfigurationDisabled(campaign) {
            return ['CAMPAIGN_AUTOMATED_RULE_BASED', 'CAMPAIGN_AUTOMATED_EVENT_BASED', 'CAMPAIGN_MBOX'].includes(campaign.campaignType) && campaign.campaignStatus === 'CAMPAIGN_ACTIVE';
        }
    },
    mounted() {
        this.tooltips();
    },
    beforeMount() {
        this.loadCampaign();
        this.listMediaLibrary();
        this.getRecipients();
    },
    updated() {
        this.tooltips();
    }
}
</script>

<style lang="scss" scoped>

.reset-configuration-modal {
    margin: auto;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 9999999;
    background-color: rgba(0, 0, 0, .3);

    .reset-configuration-modal-body {
        margin: auto;
        position: absolute;
        border-radius: 4px;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 500px;
        height: 8rem;
        padding: 1rem;
        background-color: #FFFFFF;
    }
}
</style>
