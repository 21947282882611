<template>
    <ul class="d-flex list-group list-group-horizontal mx-auto my-3" v-if="!loading.CAMPAIGN_UPDATE_CHANNEL">
        <li class="list-group-item"
            :class="getListElementClass('variables')"
            @click="$emit('step', 2)">
            <span :class="getConfigClass('variables')">
                <template v-if="getConfigStatus('variables')">
                    <font-awesome-icon icon="check"/>
                </template>
                <strong v-else>1</strong>
            </span>
            <p class="mb-0">{{ $tc('campaign', 1) }}</p>
        </li>
        <li class="list-group-item"
            :class="getListElementClass('recipients')"
            @click="$emit('step', 5)">
            <span :class="getConfigClass('recipients')">
                <template v-if="getConfigStatus('recipients')">
                    <font-awesome-icon icon="check"/>
                </template>
                <strong v-else>2</strong>
            </span>
            <p class="mb-0">{{ $tc('recipient', 2) }}</p>
        </li>
        <li class="list-group-item"
            :class="getListElementClass('recipientMapping')"
            @click="emitStep('recipientMapping')">
            <span :class="getConfigClass('recipientMapping')">
                <template v-if="getConfigStatus('recipientMapping')">
                    <font-awesome-icon icon="check"/>
                </template>
                <strong v-else>3</strong>
            </span>
            <p class="mb-0">{{ $tc('mapping', 1) }}</p>
        </li>
        <li class="list-group-item"
            :class="getListElementClass('summary')"
            @click="emitStep('summary')">
            <span class="bg-info" :class="getConfigClass('summary')">
                <font-awesome-icon icon="tasks"/>
            </span>
            <p class="mb-0">{{ $tc('summary', 1) }}</p>
        </li>
    </ul>
    <ul class="loading d-flex list-group list-group-horizontal mx-auto my-3" v-else>
        <li class="list-group-item">
            <component-shimmer width="48px" height="48px"/>
        </li>
        <li class="list-group-item">
            <component-shimmer width="48px" height="48px"/>
        </li>
        <li class="list-group-item">
            <component-shimmer width="48px" height="48px"/>
        </li>
        <li class="list-group-item">
            <component-shimmer width="48px" height="48px"/>
        </li>
    </ul>
</template>

<script>
import ComponentShimmer from "../ComponentShimmer";

export default {
    name: "ComponentStepper",
    components: {ComponentShimmer},
    computed: {
        loading() {
            return {
                CAMPAIGN_UPDATE_CHANNEL: this.$store.state.loading.CAMPAIGN_UPDATE_CHANNEL
            }
        },
        campaign() {
            return this.$store.state.CAMPAIGN.campaign;
        },
        configExists() {
            const config = this.$store.state.CAMPAIGN.campaign.config;

            if (config === null) {
                return false;
            }

            return config.campaignData !== null;
        },
        recipientsConfigured() {
            if (this.recipientConfig && Object.hasOwnProperty.call(this.recipientConfig, 'sourceType')) {
                return this.recipientConfig.sourceType !== 'UNDEFINED';
            }

            return false;
        },
        recipientConfig() {
            return this.$store.state.CAMPAIGN.recipientConfig;
        },
    },
    props: {
        step: {
            Type: Number,
            required: true
        }
    },
    methods: {
        getListElementClass(section) {
            const pending = 'pending';

            if (!this.configExists) {
                return pending;
            }

            switch (section) {
                case 'variables':
                    if (this.campaign.config === null) {
                        return pending;
                    }
                    break;
                case 'recipientMapping':
                    if (this.campaign.config.recipientMapping === null) {
                        return pending;
                    } else if (this.campaign.config.versioning.recipientMapping !== this.campaign.config.versioning.variables) {
                        return pending
                    } else if (this.campaign.config.versioning.recipientMapping !== this.campaign.config.versioning.recipients) {
                        return pending;
                    }

                    break;
                case 'recipients':
                    if (!this.recipientsConfigured) {
                        return pending;
                    }
                    break;
                case 'summary':
                    if (!this.$store.getters['CAMPAIGN/isCampaignFullyConfigured']) {
                        return pending;
                    }

                    break;
            }

            return 'complete';
        },
        getConfigClass(section) {
            const invalid = 'bg-warning text-white';

            if (!this.configExists) {
                return invalid;
            }

            switch (section) {
                case 'variables':
                    if (this.campaign.config === null) {
                        return invalid;
                    }
                    break;
                case 'recipientMapping':
                    if (this.campaign.config.recipientMapping === null) {
                        return invalid;
                    } else if (this.campaign.config.versioning.recipientMapping !== this.campaign.config.versioning.variables) {
                        return invalid
                    } else if (this.campaign.config.versioning.recipientMapping !== this.campaign.config.versioning.recipients) {
                        return invalid;
                    }

                    break;
                case 'recipients':
                    if (!this.recipientsConfigured) {
                        return invalid;
                    }
                    break;
                case 'summary':
                    if (!this.$store.getters['CAMPAIGN/isCampaignFullyConfigured']) {
                        return invalid;
                    }

                    break;
            }

            return 'bg-success text-white';
        },
        getConfigStatus(section) {
            if (!this.configExists) {
                return false;
            }

            switch (section) {
                case 'variables':
                    return this.campaign.config !== null;
                case 'recipientMapping':
                    if (this.campaign.config.recipientMapping === null) {
                        return false;
                    } else if (this.campaign.config.versioning.recipientMapping !== this.campaign.config.versioning.variables) {
                        return false
                    } else if (this.campaign.config.versioning.recipientMapping !== this.campaign.config.versioning.recipients) {
                        return false;
                    }

                    break;
                case 'recipients':
                    return this.recipientsConfigured;
                case 'summary':
                    if (this.campaign.config === null) {
                        return false;
                    } else if (this.campaign.config.recipientMapping === null) {
                        return false;
                    } else if (this.campaign.config.versioning.recipientMapping !== this.campaign.config.versioning.variables) {
                        return false
                    } else if (this.campaign.config.versioning.recipientMapping !== this.campaign.config.versioning.recipients) {
                        return false;
                    } else if (!this.recipientsConfigured) {
                        return false;
                    }

                    break;
            }

            return true;
        },
        emitStep(section) {
            let step;

            switch (section) {
                case 'recipientMapping':
                    if (Object.keys(this.recipientConfig).length === 0) {
                        break;
                    }

                    switch (this.recipientConfig.sourceType) {
                        case 'IMPORT':
                            step = 11;
                            break;
                        case 'CONTACT':
                        case 'SEGMENT':
                        case 'RULE':
                            step = 13;
                            break;
                    }

                    this.$emit('step', step);
                    break;
                case 'summary':
                    if (this.getConfigStatus('summary')) {
                        this.$emit('step', 14);
                    }

                    break;
            }
        }
    }
}
</script>

<style scoped lang="scss">
ul {
    li {
        display: flex;
        width: 100%;
        border: 0;
        cursor: pointer;
        text-align: center;
        flex-direction: column;
        -ms-flex-direction: column;
        -webkit-flex-direction: column;

        span, div {
            display: flex;
            width: 3rem;
            height: 3rem;
            z-index: 1;
            font-size: 1.5rem;
            border-radius: 50%;
            -webkit-justify-content: center;
            justify-content: center;
            margin-bottom: 0.5rem;
            -webkit-align-self: center;
            align-self: center;
            -webkit-align-items: center;
            align-items: center;
            -webkit-transition: all .2s;
            -moz-transition: all .2s;
            -ms-transition: all .2s;
            -o-transition: all .2s;
            transition: all .2s;

            &:hover {
                transform: scale(1.1);
            }
        }

        p {
            font-weight: 600;
        }

        &::before,
        &::after {
            z-index: 0;
            position: absolute;
            top: 2rem;
            display: block;
            content: "";
            width: calc(50% - 3rem);
            height: .25rem;
            border-radius: .25rem;
            background-color: var(--bs-warning);
            pointer-events: none;
        }

        &::before {
            left: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        &::after {
            right: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        &:first-child {
            &::before {
                display: none;
            }
        }

        &:last-child {
            &::after {
                display: none;
            }
        }

        &.complete {
            &::after {
                background-color: var(--bs-success);
            }

            &+li.list-group-item {
                &::before {
                    background-color: var(--bs-success);
                }
            }
        }
    }

    &.loading {
        li {
            &::before,
            &::after {
                background-color: var(--bs-light);
            }
        }
    }
}

</style>