<template>
    <div class="step px-3">
        <div class="row mb-3">
            <div class="col-12">
                <label class="form-label h5 fw-bold" for="campaign-name">
                    {{ $t('modal.recipients-and-campaigns-configuration.dp-campaign-name') }}
                </label>

                <input id="campaign-name"
                       v-model="campaignName"
                       :class="{ 'is-invalid': this.$v.campaignName.$invalid }"
                       class="form-control w-100"
                       pattern='[^<>?":|\\/*]{0,30}'
                       required="required"
                       title="30 characters maximum, the following special characters are not allowed:"
                       type="text">
                <p :class="{ 'invalid-feedback': this.$v.campaignName.$invalid }" class="form-text">
                    {{ $t('modal.recipients-and-campaigns-configuration.campaign-character-limit-description') }}
                    <a v-if="campaign.outboundChannels"
                       :href="campaign.outboundChannels[0].config.printMailingAutomationUrl" rel="noopener noreferrer"
                       target="_blank">
                        <font-awesome-icon icon="external-link-alt"/>
                        {{ $t('modal.recipients-and-campaigns-configuration.campaign-character-limit-url') }}
                    </a>
                </p>
            </div>
            <div class="col-12">
                <label class="form-label h5 fw-bold" for="campaign-list">
                    {{ $tc('campaign', 1) }}
                </label>
                <select id="campaign-list"
                        v-model="temporaryCampaignUniqueId"
                        :class="{ 'is-invalid': this.$v.temporaryCampaignUniqueId.$invalid }"
                        class="form-select">
                    <option value="">-- {{ $t('actions.please-select') }} --</option>
                    <option v-for="temporaryCampaign in campaigns.list"
                            :key="temporaryCampaign.campaignUniqueId"
                            :value="temporaryCampaign.campaignUniqueId">
                        {{ temporaryCampaign.campaignDescShort }}
                    </option>
                </select>
                <div v-if="!this.$v.temporaryCampaignUniqueId.required" class="invalid-feedback">
                    {{ $t('validation.error.required.campaign') }}
                </div>
            </div>
        </div>
        <form v-if="temporaryCampaignUniqueId" class="position-relative">
            <div class="row mt-3">
                <div class="col-12">
                    <h2 class="h5"><strong>{{ $tc('variable', 2) }}</strong></h2>
                    <div class="form-text">
                        {{ $t('pages.campaign-settings.variables-message') }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 mb-3">

                    <ul class="list-group mt-1 p-0">

                        <li class="list-group-item">
                            <div class="d-flex justify-content-between">
                                <strong style="flex: 1 0 10%">{{ $tc('content', 1) }}</strong>
                                <strong style="flex: 1 0 20%">{{
                                        $t('modal.recipients-and-campaigns-configuration.dp-variable-name')
                                    }}</strong>
                                <strong style="flex: 1 0 20%">{{ $tc('type', 1) }}</strong>
                                <strong style="">&nbsp;</strong>
                            </div>
                        </li>

                        <li v-for="(variable, i) in variables"
                            :key="'variable-new-' + i"
                            class="list-group-item">

                            <div class="d-flex justify-content-between">

                                <!-- FIELD -->
                                <div class="me-3" style="flex: 1 0 10%">
                                    <label :for="'campaign-variable-' + i + '-field'" class="sr-only">
                                        <strong>{{ $tc('field', 1) }}</strong>
                                    </label>
                                    <select :id="'campaign-variable-' + i + '-field'"
                                            v-model="variable.field" class="form-select">
                                        <option v-for="(key, value) in fields"
                                                :key="'variable-field-' + key"
                                                :value="value">
                                            {{ $t('variables.' + value) }}
                                        </option>
                                        <option :value="null">{{ $t('variables.OTHER') }}</option>
                                    </select>
                                </div>

                                <!-- VARIABLE NAME -->
                                <div class="form-group mb-0 me-3" style="flex: 1 0 20%">
                                    <label :for="'campaign-variable-' + i + '-name'" class="form-label sr-only">
                                        <strong>{{ $tc('variable-name', 1) }}</strong>
                                    </label>
                                    <input :id="'campaign-variable-' + i + '-name'"
                                           v-model="variable.name"
                                           :class="{ 'is-invalid': v.variables.$each[i].name.$invalid }"
                                           :name="'campaign_variable[' + i + '][name]'"
                                           class="form-control w-100"
                                           required="required"
                                           type="text">
                                    <div v-if="!v.variables.$each[i].name.required" class="invalid-feedback">
                                        {{ $t('validation.error.required.variable-name') }}
                                    </div>
                                    <div v-if="!v.variables.$each[i].name.maxLength" class="invalid-feedback">
                                        {{
                                            $t('validation.error.maxLength.variable-name', {count: v.variables.$each[i].name.$params.maxLength.max})
                                        }}
                                    </div>
                                </div>

                                <!-- VARIABLE TYPE -->
                                <div class="form-group mb-0 me-3 flex-grow-1" style="flex: 1 0 20%">
                                    <label :for="'campaign-variable-' + i + '-type'" class="form-label sr-only">
                                        <strong>{{ $tc('variable-type', 1) }}</strong>
                                    </label>
                                    <select :id="'campaign-variable-' + i + '-type'"
                                            v-model="variable.type"
                                            :name="'campaign_variable[' + i + '][type]'"
                                            class="form-select w-100"
                                            required="required">
                                        <option v-for="(value, i) in variableType"
                                                :key="'variable-new-variable-type-' + i"
                                                :value="value">
                                            {{ value }}
                                        </option>
                                    </select>
                                </div>

                                <!-- REMOVE VARIABLE -->
                                <div class="d-flex justify-content-center flex-grow-0"
                                     style="flex-shrink: 1">
                                    <div style="margin: 0 auto;">
                                        <button :disabled="i < 7"
                                                :title="$tc('actions.delete')"
                                                class="btn btn-outline-danger"
                                                type="button"
                                                @click="onRemoveVariable(i)">
                                            <span class="sr-only">{{ $tc('actions.delete') }}</span>
                                            <font-awesome-icon icon="trash"/>
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </li>
                    </ul>

                    <div class="mt-2 text-right">
                        <button :disabled="variables.length > 26"
                                class="btn btn-light"
                                type="button"
                                @click="onAddVariable">
                            <font-awesome-icon class="me-1" icon="plus"/>
                            {{ $t('actions.add-variable') }}
                        </button>
                    </div>
                    <div v-if="variables.length > 26" class="alert alert-info d-flex align-items-center">
                        <font-awesome-icon class="me-3" icon="info-circle" size="2x"/>
                        You can add total of 20 additional variables.
                    </div>
                </div>
            </div>
            <component-loading v-if="loading.CAMPAIGN_GET && !loading.CAMPAIGN_LIST"
                               background-color="rgba(255,255,255,.6)"/>
        </form>
        <div v-else class="col-12">
            <div class="alert alert-info d-flex align-items-center mb-4">
                <font-awesome-icon class="ms-2 me-3" icon="info" size="2x"/>
                {{ $t('modal.recipients-and-campaigns-configuration.select-campaign-description') }}
            </div>
        </div>
        <component-loading v-if="loading.CAMPAIGN_LIST && !loading.CAMPAIGN_GET"
                           background-color="rgba(255,255,255,.6)"/>
    </div>
</template>
<script>
import ComponentLoading from "../../../components/ui/ComponentLoading";
import {maxLength, required} from "vuelidate/lib/validators";

export default {
    name: "ComponentReuseVariableConfiguration",
    components: {ComponentLoading},
    computed: {
        v() {
            return this.$v;
        },
        formValid() {
            return !this.$v.$invalid;
        },
        loading() {
            return {
                CSV: false,
                CONTACT_LIST: this.$store.state.loading.CONTACT_LIST,
                EVENT_TRIGGER_CAMPAIGN: this.$store.state.loading.EVENT_TRIGGER_CAMPAIGN,
                CAMPAIGN_GET: this.$store.state.loading.CAMPAIGN_GET,
                CAMPAIGN_LIST: this.$store.state.loading.CAMPAIGN_LIST,
            }
        },
        campaign() {
            return this.$store.state.CAMPAIGN.campaign;
        },
        campaigns() {
            return this.$store.state.CAMPAIGN.campaigns;
        },
        temporaryCampaign() {
            return this.$store.state.CAMPAIGN.temporaryCampaign;
        },
        versioning() {
            if (this.configExists) {
                let version = this.campaign.config.versioning;
                version.variables = this.campaign.config.versioning.variables + 1;
                return version;
            } else {
                return {
                    variables: 2,
                    recipients: 1,
                    recipientMapping: 1
                }
            }
        }
    },
    props: {
        fields: {
            Type: Object,
            required: true
        },
        variableType: {
            Type: Object,
            required: true
        },
        configExists: {
            Type: Boolean,
            required: false
        }
    },
    data() {
        return {
            campaignName: '',
            temporaryCampaignUniqueId: '',
            variables: []
        }
    },
    methods: {
        async getCampaign(campaignUniqueId) {
            await this.$store.dispatch('CAMPAIGN/GET_TEMPORARY', campaignUniqueId);
        },
        onAddVariable() {
            this.variables.push({
                name: '',
                type: 'string',
                field: null
            });
        },
        onRemoveVariable(index) {
            this.variables.splice(index, 1);
        },
        saveConfiguration() {
            this.$store.dispatch('CAMPAIGN/UPDATE_CHANNEL', {
                campaignUniqueId: this.campaign.campaignUniqueId,
                channelUniqueId: this.campaign.outboundChannels[0].channelUniqueId,
                saveExternalCampaign: false,
                configData: {
                    versioning: this.versioning,
                    campaignData: {
                        campaignName: this.campaignName,
                        startDate: '2020-01-01',
                        endDate: null
                    },
                    printNodes: [
                        {
                            printNodeId: 'Print_Node_01',
                            description: 'Print_Node_01'
                        }
                    ],
                    variables: this.variables,
                    staticValues: this.campaign.config ? this.campaign.config.staticValues : null,
                    sourceColumns: this.campaign.config ? this.campaign.config.sourceColumns : null,
                    recipientMapping: this.campaign.config ? this.campaign.config.recipientMapping : null,
                    configSourceType: 'PREVIOUS_CAMPAIGN',
                    printMailingAutomationCampaignUniqueId: this.campaign.config
                        ? this.campaign.config.printMailingAutomationCampaignUniqueId
                        : null
                }
            })
                .catch(() => {
                    this.$store.dispatch('toast/danger', {
                        title: this.$tc('toast.danger.error'),
                        message: this.$tc('toast.danger.campaign-channel-updated')
                    });
                });
        },
        checkFormValidation() {
            this.$emit('validateForm', 'previousCampaign', this.formValid)
        }
    },
    validations: {
        campaignName: {
            required,
            maxLength: maxLength(30)
        },
        temporaryCampaignUniqueId: {
            required
        },
        variables: {
            $each: {
                name: {
                    required,
                    maxLength: maxLength(30)
                }
            }
        }
    },
    watch: {
        campaign(campaign) {
            if (campaign.config && campaign.config['campaignData']['campaignName']) {
                this.campaignName = campaign.config.campaignData.campaignName;
            } else {
                this.campaignName = campaign.campaignDescShort;
            }
        },
        async temporaryCampaignUniqueId(campaignUniqueId) {
            await this.getCampaign(campaignUniqueId);

            if (this.temporaryCampaign.config) {
                this.variables = this.temporaryCampaign.config.variables;
            }
        },
        formValid() {
            this.checkFormValidation();
        }
    }
}
</script>
