<template>
    <div class="step px-3">
        <div class="row">
            <div class="col-12">
                <div class="alert alert-info d-flex align-items-center">
                    <font-awesome-icon class="me-3" icon="info-circle" size="2x"/>
                    {{ $t('alerts.info.variable-mapping-contact') }}
                </div>
            </div>

            <div v-if="!loading.CAMPAIGN_GET && campaign.config" class="col-12 mb-3">
                <table class="table table-responsive table-hover">
                    <thead>
                    <tr>
                        <th scope="col" style="width: 50%">
                            <strong>{{ $tc('variable-name', 1) }}</strong>
                        </th>
                        <th scope="col" style="width: 50%">
                            <strong>{{ $tc('contact-field', 1) }}</strong>
                        </th>
                    </tr>
                    <tr v-for="(variable, i) in variables"
                        :key="'variable-mapping-' + i">
                        <th class="align-middle"><strong>{{ variable.name }}</strong></th>
                        <td class="d-flex">
                            <div class="w-100">
                                <select v-model="standardFields[variable.name]"
                                        :class="{ 'is-invalid': !standardFields[variable.name] }"
                                        class="form-select"
                                        @change="deleteCustomValue(variable.name)">
                                    <option value="">&mdash; {{ $t('actions.please-select') }} &mdash;</option>
                                    <option value="custom">{{ $tc('custom-variable', 1) }}</option>
                                    <optgroup :label="$tc('address', 1)">
                                        <option value="address.salutation">{{ $tc('salutation', 1) }}</option>
                                        <option value="address.firstName">{{ $tc('first-name', 1) }}</option>
                                        <option value="address.lastName">{{ $tc('last-name', 1) }}</option>
                                        <option value="address.company">{{ $tc('company', 1) }}</option>
                                        <option value="address.vatId">{{ $t('vat-id') }}</option>
                                        <option value="address.street">{{ $tc('street', 1) }}</option>
                                        <option value="address.postcode">{{ $tc('postcode', 1) }}</option>
                                        <option value="address.city">{{ $tc('city', 1) }}</option>
                                        <option value="address.state">{{ $tc('state', 1) }}</option>
                                        <option value="address.country">{{ $tc('country', 1) }}</option>
                                        <option value="address.birthday">{{ $tc('birthday', 1) }}</option>
                                    </optgroup>
                                    <optgroup :label="$t('contact-information')">
                                        <option value="contactInformation.email">{{ $tc('email', 1) }}</option>
                                        <option value="contactInformation.phonePrivate">
                                            {{ $t('phone-private') }}
                                        </option>
                                        <option value="contactInformation.phoneBusiness">
                                            {{ $t('phone-business') }}
                                        </option>
                                        <option value="contactInformation.phoneMobile">{{ $t('phone-mobile') }}</option>
                                    </optgroup>
                                </select>
                                <div class="invalid-feedback">
                                    {{ $t('validation.error.required.mapping') }}
                                </div>
                            </div>
                            <div class="w-100 ms-2" v-if="standardFields[variable.name] === 'custom'">
                                <input v-model="customFields[variable.name]"
                                       :class="{ 'is-invalid': !customFields[variable.name] }"
                                       :placeholder="$tc('value', 1)"
                                       class="w-100 form-control"
                                       type="text">
                                <div class="invalid-feedback ms-2">
                                    {{ $t('validation.error.required.static-value') }}
                                </div>
                            </div>
                        </td>
                    </tr>
                    </thead>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ComponentContactsVariableMapping",
    computed: {
        loading() {
            return {
                CAMPAIGN_GET: this.$store.state.loading.CAMPAIGN_GET
            }
        },
        campaign() {
            return this.$store.state.CAMPAIGN.campaign;
        },
        recipientMapping() {
            let mapping = {...this.standardFields};

            for (const [value, field] of Object.entries(this.customFields)) {
                mapping[value] = field
            }

            return mapping
        },
        variables() {
            return this.$store.getters['CAMPAIGN/variables'];
        },
        versioning() {
            let version = this.campaign.config.versioning;
            version.recipientMapping = this.campaign.config.versioning.recipientMapping + 1;
            return version;
        },
        inputValid() {
            let standardFieldsValid = true;
            let customFieldsValid = true;
            let customFieldsCount = 0

            for (const field of Object.entries(this.standardFields)) {
                if (field[1] === 'custom') {
                    customFieldsCount += 1;
                }
                if (!field[1]) {
                    standardFieldsValid = false;
                }
            }

            for (const field of Object.entries(this.customFields)) {
                if (!field[1]) {
                    customFieldsValid = false;
                }
            }
            return customFieldsValid && standardFieldsValid && customFieldsCount === Object.keys(this.customFields).length;
        }
    },
    data() {
        return {
            standardFields: {},
            customFields: {}
        }
    },
    methods: {
        initMapping() {
            let mapping = {}
            let defaults = {
                FIRST_NAME: 'address.firstName',
                LAST_NAME: 'address.lastName',
                COMPANY: 'address.company',
                STREET: 'address.street',
                ZIP_CODE: 'address.postcode',
                CITY: 'address.city',
                COUNTRY: 'address.country',
            }

            this.variables.forEach(variable => {
                if (variable.field) {
                    mapping[variable.name] = defaults[variable.field];
                } else {
                    mapping[variable.name] = '';
                }
            });

            this.standardFields = mapping;
        },
        deconstructMapping() {
            const variables = this.campaign.config.variables;
            const mappedVariables = this.campaign.config.recipientMapping;
            const staticValues = this.campaign.config.staticValues;

            let defaults = {
                FIRST_NAME: 'address.firstName',
                LAST_NAME: 'address.lastName',
                COMPANY: 'address.company',
                STREET: 'address.street',
                ZIP_CODE: 'address.postcode',
                CITY: 'address.city',
                COUNTRY: 'address.country',
            }

            let standardFields = {};
            let customFields = {};

            variables.forEach(variable => {
                if (!this.isCustomVariable(defaults[variable.field])) {
                    if (mappedVariables[variable.name] === 'custom') {
                        standardFields[variable.name] = 'custom';
                        customFields[variable.name] = staticValues[variable.name];
                    } else {
                        standardFields[variable.name] = mappedVariables[variable.name];
                    }
                } else {
                    standardFields[variable.name] = 'custom';
                    customFields[variable.name] = staticValues === null ? '' : staticValues[variable.name];
                }
            });

            this.standardFields = standardFields;
            this.customFields = customFields;
        },
        deleteCustomValue(value) {
            if (this.standardFields[value] !== 'custom' && Object.hasOwnProperty.call(this.customFields, value)) {
                delete this.customFields[value];
            }
        },
        saveConfiguration() {
            this.$store.dispatch('CAMPAIGN/UPDATE_CHANNEL', {
                campaignUniqueId: this.campaign.campaignUniqueId,
                channelUniqueId: this.campaign.outboundChannels[0].channelUniqueId,
                saveExternalCampaign: false,
                configData: {
                    versioning: this.versioning,
                    campaignData: {
                        campaignName: this.campaign.config ? this.campaign.config.campaignData.campaignName : null,
                        startDate: '2020-01-01',
                        endDate: null
                    },
                    printNodes: [
                        {
                            printNodeId: 'Print_Node_01',
                            description: 'Print_Node_01'
                        }
                    ],
                    variables: this.campaign.config ? this.campaign.config.variables : null,
                    staticValues: this.customFields,
                    sourceColumns: this.campaign.config ? this.campaign.config.sourceColumns : null,
                    recipientMapping: this.standardFields,
                    configSourceType: this.campaign.config ? this.campaign.config.configSourceType : null,
                    printMailingAutomationCampaignUniqueId: this.campaign.config
                        ? this.campaign.config.printMailingAutomationCampaignUniqueId
                        : null
                },
            })
        },
        isCustomVariable(variable = null) {
            const predefinedVariables = [
                'address.salutation',
                'address.firstName',
                'address.lastName',
                'address.company',
                'address.vatId',
                'address.street',
                'address.postcode',
                'address.city',
                'address.state',
                'address.country',
                'address.country',
                'address.birthday',
                'contactInformation.email',
                'contactInformation.phonePrivate',
                'contactInformation.phoneBusiness',
                'contactInformation.phoneMobile'
            ];

            return !predefinedVariables.includes(variable);
        },
        checkFormValidation() {
            this.$emit('validateForm', 'variableMapping', this.inputValid)
        }
    },
    beforeMount() {
        this.initMapping();
    },
    watch: {
        inputValid() {
            this.checkFormValidation();
        }
    },
}
</script>
