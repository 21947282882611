<template>
    <div class="step px-3">
        <div class="row">
            <div class="col-12">
                <div class="alert alert-info d-flex align-items-center">
                    <font-awesome-icon class="me-3" icon="info-circle" size="2x"/>
                    {{ $t('alerts.info.contacts-de-only') }}
                </div>
            </div>

            <div class="col-12">
                <component-actions :action-filters="actionFilters"
                                   :bulk="bulk"
                                   :bulk-actions="bulkActions"
                                   :extended-search="extendedSearch"
                                   :general-search-fields="generalSearchFields"
                                   :itemsPerPage="itemsPerPage"
                                   :page="page"
                                   :primary-actions="primaryActions"
                                   :secondary-actions="secondaryActions"
                                   :total="total"
                                   @applyFilters="onApplyFilters"
                                   @changeItemsPerPage="onChangeItemsPerPage"
                                   @paginate="onChangePage"
                                   @refresh="listContacts"/>
            </div>
            <div class="col-12">
                <table class="table">
                    <thead>
                    <tr>
                        <th scope="col">
                            <input v-model="all"
                                   class="form-check"
                                   type="checkbox"
                                   @change="onSelectAll"/>
                        </th>
                        <th scope="col">{{ $tc('salutation', 1) }}</th>
                        <th scope="col">{{ $tc('first-name', 1) }}</th>
                        <th scope="col">{{ $tc('last-name', 1) }}</th>
                        <th scope="col">{{ $tc('company', 1) }}</th>
                        <th scope="col">{{ $tc('street', 1) }}</th>
                        <th scope="col">{{ $tc('postcode', 1) }}</th>
                        <th scope="col">{{ $tc('city', 1) }}</th>
                        <th scope="col">{{ $tc('country', 1) }}</th>
                        <th scope="col">{{ $tc('email', 1) }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-cloak v-if="!loading.CONTACT_LIST && contacts.list.length > 0">
                        <tr v-for="(contact, i) in contacts.list" :key="'contact-' + i">
                            <td>
                                <input v-model="checked"
                                       :value="contact.contactUniqueId"
                                       class="form-check"
                                       type="checkbox">
                            </td>
                            <td>{{ contact.address.salutation }}</td>
                            <td>{{ contact.address.firstName }}</td>
                            <td>{{ contact.address.lastName }}</td>
                            <td>{{ contact.address.company }}</td>
                            <td>{{ contact.address.street }}</td>
                            <td>{{ contact.address.postcode }}</td>
                            <td>{{ contact.address.city }}</td>
                            <td>
                                <template v-if="contact.address.country">
                                    <span>{{ countries[contact.address.country].name }}</span>
                                </template>
                            </td>
                            <td>{{ contact.contactInformation.email }}</td>
                        </tr>
                    </template>
                    <template v-cloak v-if="!loading.CONTACT_LIST && contacts.list.length === 0">
                        <tr>
                            <td colspan="99">
                                <div class="py-3 text-primary d-flex align-items-center justify-content-center flex-column mb-0"
                                     role="alert">
                                    <font-awesome-icon icon="exclamation-triangle" size="3x"/>
                                    <div class="mt-2 fs-4">{{ $t('pages.contacts.no-contacts-found') }}</div>
                                </div>
                            </td>
                        </tr>
                    </template>
                    <template v-if="loading.CONTACT_LIST">
                        <tr v-for="n in 10" :key="'row-shimmer-' + n">
                            <td class="align-middle">
                                <component-shimmer height="14px" style="margin: 4px 0;" width="14px"/>
                            </td>
                            <td class="align-middle"><component-shimmer width="100%"/></td>
                            <td class="align-middle"><component-shimmer width="100%"/></td>
                            <td class="align-middle"><component-shimmer width="100%"/></td>
                            <td class="align-middle"><component-shimmer width="100%"/></td>
                            <td class="align-middle"><component-shimmer width="100%"/></td>
                            <td class="align-middle"><component-shimmer width="100%"/></td>
                            <td class="align-middle"><component-shimmer width="100%"/></td>
                            <td class="align-middle"><component-shimmer width="100%"/></td>
                            <td class="align-middle"><component-shimmer width="100%"/></td>
                        </tr>
                    </template>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import ComponentActions from "../../../components/ui/ComponentActions";
import ComponentShimmer from "../../../components/ComponentShimmer";
import {countries} from "countries-list";

export default {
    name: "ComponentContactRecipients",
    components: {ComponentActions, ComponentShimmer},
    props: {
        recipientsConfigured: {
            Type: Boolean,
            required: true
        }
    },
    computed: {
        loading() {
            return {
                CONTACT_LIST: this.$store.state.loading.CONTACT_LIST
            }
        },
        campaign() {
            return this.$store.state.CAMPAIGN.campaign;
        },
        segments() {
            return this.$store.state.SEGMENT.segments;
        },
        contacts() {
            return this.$store.state.CONTACT.contacts;
        },
        actionFilters() {
            let segments = [];

            this.segments.list.forEach(segment => {
                segments.push({name: segment.segmentDescShort, value: segment.segmentUniqueId});
            });

            return [
                {
                    title: this.$tc('segment', 1),
                    key: 'genSegmentContactRels.genSegment.gen_segment_unique_id',
                    values: segments
                }
            ];
        },
        recipientConfig() {
            return this.$store.state.CAMPAIGN.recipientConfig;
        },
        versioning() {
            let version = this.campaign.config.versioning;
            version.recipients = this.campaign.config.versioning.recipients + 1;
            return version;
        },
    },
    data() {
        return {
            countries,
            all: true,
            checked: [],
            filter: {
                'genContactAddressRels.genAddress.gen_address_country': 'DE'
            },
            order: {meta_insert_dt: 'DESC'},
            page: 1,
            itemsPerPage: 25,
            total: 0,
            limit: null,
            primaryActions: [],
            secondaryActions: [],
            bulkActions: [],
            bulk: false,
            generalSearchFields: [],
            extendedSearch: [
                {
                    name: this.$tc('company', 1),
                    technicalName: 'genContactAddressRels.genAddress.gen_address_company'
                },
                {
                    name: this.$tc('postcode', 1),
                    technicalName: 'genContactAddressRels.genAddress.gen_address_postcode'
                },
                {
                    name: this.$tc('city', 1),
                    technicalName: 'genContactAddressRels.genAddress.gen_address_city'
                },
            ],
        }
    },
    methods: {
        listContacts() {
            let filter = this.filter;

            if (!Object.prototype.hasOwnProperty.call(filter, 'genContactAddressRels.genAddress.gen_address_country')) {
                filter['genContactAddressRels.genAddress.gen_address_country'] = 'DE';
            }

            this.$store.dispatch('CONTACT/LIST', {
                filter,
                order: this.order,
                page: this.page,
                itemsPerPage: this.itemsPerPage,
                limit: this.limit,
            }).then(contacts => {
                this.page = contacts.page;
                this.itemsPerPage = contacts.itemsPerPage;
                this.limit = contacts.limit;
                this.total = contacts.total;
                this.onSelectAll();
            });
        },
        listSegments() {
            this.$store.dispatch('SEGMENT/LIST', {
                filter: {},
                order: {meta_insert_dt: 'DESC'},
                page: 1,
                itemsPerPage: null,
                limit: null
            });
        },
        onSelectAll() {
            if (this.all === false) {
                this.checked = [];
            } else {
                this.contacts.list.forEach(contact => {
                    if (!this.checked.includes(contact.contactUniqueId)) {
                        this.checked.push(contact.contactUniqueId);
                    }
                });
            }
        },
        onChangePage(page) {
            if (page <= 0) {
                return;
            }

            this.page = parseInt(page, 10);
            this.listContacts();
        },
        onChangeItemsPerPage(itemsPerPage) {
            if (this.itemsPerPage === itemsPerPage) {
                return;
            }

            this.itemsPerPage = itemsPerPage;
            this.listContacts();
        },
        onApplyFilters(filters) {
            this.all = true;
            this.checked = [];
            this.filter = filters;
            this.listContacts();
        },
        saveRecipientsConfig() {
            this.$store.dispatch('CAMPAIGN/SAVE_RECIPIENT_CONFIG', {
                campaignUniqueId: this.campaign.campaignUniqueId,
                sourceType: 'CONTACT',
                sourceName: null,
                contactUniqueIds: this.checked,
                segmentUniqueIds: [],
                ruleUniqueId: null,
                importConfig: {
                    mediaObjectUniqueId: null,
                    processJobUniqueId: null,
                    workflowUniqueId: null
                }
            })
                .then(() => {
                    this.$store.dispatch('CAMPAIGN/GET_RECIPIENT_CONFIG', this.campaign.campaignUniqueId);
                });

            this.$store.dispatch('CAMPAIGN/UPDATE_CHANNEL', {
                campaignUniqueId: this.campaign.campaignUniqueId,
                channelUniqueId: this.campaign.outboundChannels[0].channelUniqueId,
                saveExternalCampaign: false,
                configData: {
                    versioning: this.versioning,
                    campaignData: {
                        campaignName: this.campaign.config.campaignData.campaignName,
                        startDate: '2020-01-01',
                        endDate: null
                    },
                    printNodes: [
                        {
                            printNodeId: 'Print_Node_01',
                            description: 'Print_Node_01'
                        }
                    ],
                    variables: this.campaign.config ? this.campaign.config.variables : null,
                    staticValues: this.campaign.config ? this.campaign.config.staticValues : null,
                    sourceColumns: this.campaign.config ? this.campaign.config.sourceColumns : null,
                    recipientMapping: this.campaign.config ? this.campaign.config.recipientMapping : null,
                    configSourceType: this.campaign.config ? this.campaign.config.configSourceType: null,
                    printMailingAutomationCampaignUniqueId: this.campaign.config
                        ? this.campaign.config.printMailingAutomationCampaignUniqueId
                        : null
                },
            })
                .catch(() => {
                    this.$store.dispatch('toast/danger', {
                        title: this.$tc('toast.danger.error'),
                        message: this.$tc('toast.danger.campaign-channel-updated')
                    });
                });
        },
    },
    watch: {
        loading(loading) {
            if (loading.CONTACT_LIST) {
                this.$emit('validateForm', 'contactRecipients', false)
            } else {
                this.$emit('validateForm', 'contactRecipients', true)
            }
        }
    },
    beforeMount() {
        if (this.recipientsConfigured) {
            if (this.recipientConfig.sourceType === 'CONTACT') {
                this.checked = this.recipientConfig.contactUniqueIds;
            }
        }
    }
}
</script>
