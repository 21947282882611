<template>
    <div class="step px-3">
        <div v-if="!loading.CAMPAIGN_GET" class="row">
            <div class="col-6">
                <div class="card mb-3">
                    <div class="card-header">
                        <strong>{{ $t('modal.recipients-and-campaigns-configuration.campaign-settings') }}</strong>
                    </div>
                    <ul class="list-group list-group-flush" v-if="campaign.config">
                        <li class="list-group-item d-flex justify-content-between">
                            <strong>{{ $t('campaign-name') }}</strong>
                            <span>{{ campaign.config.campaignData.campaignName }}</span>
                        </li>
                        <li class="list-group-item d-flex justify-content-between">
                            <strong>{{ $t('modal.recipients-and-campaigns-configuration.starting-point') }}</strong>
                            <span>{{ $t('modal.recipients-and-campaigns-configuration.starting-points.' + campaign.config.configSourceType) }}</span>
                        </li>
                    </ul>
                </div>

                <div class="card">
                    <div class="card-header">
                        <strong>{{ $t('modal.recipients-and-campaigns-configuration.dp-variables-label') }}</strong>
                    </div>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item d-flex justify-content-start">
                            <strong class="w-33">{{ $tc('content', 1) }}</strong>
                            <strong class="w-33">{{ $tc('variable', 1) }}</strong>
                            <strong class="w-33">{{ $tc('type', 1) }}</strong>
                        </li>
                        <li class="list-group-item d-flex justify-content-start"
                            v-for="(variable, i) in variables"
                            :key="'variable-new-' + i">
                            <span class="w-33">
                                <template v-if="variable.field">{{ $t('variables.' + variable.field) }}</template>
                                <i class="text-muted" v-else>{{ $t('none') }}</i>
                            </span>
                            <span class="w-33">{{ variable.name }}</span>
                            <span class="w-33">{{ variable.type }}</span>
                        </li>
                    </ul>
                </div>
            </div>

            <div v-if="recipientConfig && recipients" class="col-6">
                <div class="card mb-3">
                    <div class="card-header">
                        <strong>{{ $t('modal.recipients-and-campaigns-configuration.recipients-settings') }}</strong>
                    </div>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item d-flex justify-content-between">
                            <strong>{{ $tc('source', 1) }}</strong>
                            <span v-if="recipientConfig.sourceType">
                                {{ $t('modal.recipients-and-campaigns-configuration.recipients-source.' + recipientConfig.sourceType) }}
                            </span>
                            <span v-if="campaign.campaignType === 'CAMPAIGN_MBOX'">MBOX</span>
                        </li>
                        <li class="list-group-item d-flex justify-content-between"
                            v-if="recipientConfig.sourceType === 'SEGMENT'">
                            <strong>{{ $tc('segment-name', 1) }}</strong>
                            <span>{{ recipientConfig.sourceName }}</span>
                        </li>
                        <li class="list-group-item d-flex justify-content-between"
                            v-else-if="recipientConfig.sourceType === 'RULE'">
                            <strong>{{ $tc('rule-name', 1) }}</strong>
                            <span>{{ recipientConfig.sourceName }}</span>
                        </li>
                        <li class="list-group-item d-flex justify-content-between"
                            v-else-if="recipientConfig.sourceType === 'IMPORT'">
                            <strong>{{ $t('file-name') }}</strong>
                            <span>{{ recipientConfig.sourceName }}</span>
                        </li>

                        <li class="list-group-item d-flex justify-content-between"
                            v-if="campaign.campaignType !== 'CAMPAIGN_MBOX'">
                            <strong>{{ $t('modal.recipients-and-campaigns-configuration.recipients-count') }}</strong>
                            <span>{{ recipients.summary.total }}</span>
                        </li>
                    </ul>
                </div>

                <div class="card" v-if="campaign.campaignType !== 'CAMPAIGN_MBOX'">
                    <div class="card-header">
                        <strong>{{ $t('variable-mapping') }}</strong>
                    </div>
                    <ul class="list-group list-group-flush" v-if="Object.keys(recipientMapping).length > 0">
                        <li class="list-group-item d-flex justify-content-start">
                            <strong class="w-50">
                                {{ $t('modal.recipients-and-campaigns-configuration.campaign-variable') }}
                            </strong>
                            <strong class="w-50">
                                {{ $t('modal.recipients-and-campaigns-configuration.source-variable') }}
                            </strong>
                        </li>

                        <li class="list-group-item d-flex justify-content-start"
                            v-for="(sourceVariable, campaignVariable, i) in recipientMapping"
                            :key="'recipient-config-import-variable-' + i">
                            <span class="w-50">{{ campaignVariable }}</span>
                            <span class="w-50"
                                  v-if="recipientConfig.sourceType === 'IMPORT'">{{ sourceVariable }}</span>
                            <span class="w-50" v-else>
                                {{
                                    isCustomVariable(sourceVariable)
                                        ? $t('modal.recipients-and-campaigns-configuration.variables.' + sourceVariable)
                                        : sourceVariable
                                }}
                            </span>
                        </li>
                    </ul>
                    <div v-else class="card-body">
                        <div class="d-flex flex-column justify-content-center">
                            <font-awesome-icon class="text-warning w-100 mb-3" size="3x" icon="exclamation-triangle"/>
                            <p class="text-center mb-0" style="font-weight: 500">
                                {{ $t('modal.recipients-and-campaigns-configuration.no-variable-mapping') }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12" v-if="campaign.config && campaign.config.printMailingAutomationCampaignUniqueId">
                <div class="alert d-flex align-items-center mt-3"
                     :class="{ 'alert-warning': !confirmation, 'alert-success': confirmation }">
                    <div class="me-3">
                        <font-awesome-icon v-show="confirmation"
                                           class="complete text-white"
                                           icon="check-circle"
                                           size="3x"/>
                        <font-awesome-icon v-show="!confirmation"
                                           class="pending text-white"
                                           icon="exclamation-circle"
                                           size="3x"/>
                    </div>
                    <input id="campaign-config-deactivation-confirmation"
                           v-model="confirmation"
                           class="form-check-input me-2 mt-0"
                           type="checkbox">
                    <label class="form-check-label"
                           for="campaign-config-deactivation-confirmation"
                           v-html="$t('modal.recipients-and-campaigns-configuration.configuration-confirmation-message', {
                               url: campaign.outboundChannels[0].config.printMailingAutomationUrl,
                               class: 'text-white'
                           })"/>
                </div>
            </div>
            <component-loading v-if="loading.CAMPAIGN_UPDATE_CHANNEL" background-color="rgba(255,255,255,.6)"/>
        </div>
    </div>
</template>

<script>
import ComponentLoading from "../../../components/ui/ComponentLoading";

export default {
    name: "ComponentConfigurationSummary",
    components: {ComponentLoading},
    data() {
        return {
            confirmation: false
        }
    },
    props: {
        campaignSent: {
            Type: Boolean,
            required: true
        },
        variablesMapped: {
            Type: Boolean,
            required: true
        }
    },
    computed: {
        loading() {
            return {
                CAMPAIGN_GET: this.$store.state.loading.CAMPAIGN_GET,
                CAMPAIGN_UPDATE_CHANNEL: this.$store.state.loading.CAMPAIGN_UPDATE_CHANNEL,
            }
        },
        campaign() {
            return this.$store.state.CAMPAIGN.campaign;
        },
        recipientConfig() {
            return this.$store.state.CAMPAIGN.recipientConfig;
        },
        recipients() {
            return this.$store.state.CAMPAIGN.recipients;
        },
        variables() {
            return this.$store.getters['CAMPAIGN/variables'];
        },
        recipientMapping() {
            return this.$store.getters['CAMPAIGN/recipientMapping'];
        }
    },
    watch: {
        confirmation() {
            this.checkFormValidation();
        }
    },
    methods: {
        getRecipientConfig() {
            this.$store.dispatch('CAMPAIGN/GET_RECIPIENT_CONFIG', this.$route.params.id).catch(() => {});
        },
        getRecipients() {
            this.$store.dispatch('CAMPAIGN/PREVIEW_RECIPIENT', {
                campaignUniqueId: this.$route.params.id,
                limit: 1
            }).catch(() => {
            });
        },
        isCustomVariable(variable) {
            return [
                'address.salutation',
                'address.firstName',
                'address.lastName',
                'address.company',
                'address.vatId',
                'address.street',
                'address.postcode',
                'address.city',
                'address.state',
                'address.country',
                'address.country',
                'address.birthday',
                'contactInformation.email',
                'contactInformation.phonePrivate',
                'contactInformation.phoneBusiness',
                'contactInformation.phoneMobile'
            ].includes(variable);
        },
        checkFormValidation() {
            if (this.campaignSent) {
                this.$emit('validateForm', 'updateCampaign', this.confirmation);
            } else {
                this.$emit('validateForm', 'updateCampaign', true);
            }
        }
    }
}
</script>

<style scoped lang="scss">
.w-33 {
    flex-basis: 33.33%;
    -webkit-flex-basis: 33.33%;
}
</style>
