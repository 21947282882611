<template>
    <div class="position-relative">
        <input class="form-select"
                :class="{ valid: selectedItem }"
                type="text"
                v-model="inputValue"
                @focus="openDropdown()"
                @blur="open = false;">
        <div class="dropdown-list-container"
            :class="{'active': open}"
            ref="dropdownListContainer">
            <template v-if="resultList.length > 0">
                <div class="dropdown-list-item"
                    v-for="(result, i) in resultList"
                    :key="'search-result-' + i "
                    @mousedown.prevent
                    @click="selectItem(result)">
                    {{ result[searchValueName] }}
                </div>
            </template>
            <div v-if="loading" class="loading-list-item">
                <font-awesome-icon icon="circle-notch" size="2x"/>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "ComponentSearchableDropdown",
    data() {
        return {
            inputValue: '',
            open: false,
            page: 1,
            resultList: [],
            selectedItem: null,
            loading: false
        }
    },
    props: {
        vuexAction: {
            type: String,
            required: true
        },
        additionalFilters: {
            type: Object,
            required: false,
            default: ()=>{}
        },
        vuexResultsState: {
            type: Object,
            required: true
        },
        order: {
            type: String,
            required: false,
            default: 'DESC'
        },
        searchColumnName: {
            type: String,
            required: true
        },
        searchValueName: {
            type: String,
            required: true
        }
    },
    watch: {
        inputValue: async function() {
            await this.getList(true);
            if ( this.selectedItem ) {
                if ( this.selectedItem[this.searchValueName] !== this.inputValue ) {
                    this.selectedItem = null;
                }
            }
            this.$emit('onItemSelect', this.selectedItem)
        }
    },
    computed: {
        results() {
            return this.vuexResultsState;
        }
    },
    methods: {
        openDropdown() {
            this.open = true;
            this.getList(true);
        },
        getList(newList = false) {
            if (newList) {
                this.resultList = [];
                this.page = 1;
            }
            if ( this.selectedItem ) {
                if ( this.selectedItem[this.searchValueName] === this.inputValue ) return;
            }
            this.open = true;
            this.loading = true;
            this.$store.dispatch(this.vuexAction, {
                filter: {
                    ...this.additionalFilters,
                    [this.searchColumnName]: {
                        like: `%${this.inputValue}%`
                    },
                },
                order: {
                    meta_insert_dt: this.order
                },
                page: this.page,
                itemsPerPage: 25,
                limit: null
            })
                .then(() => {
                    if (this.page === this.results.page)
                    this.resultList.push(...this.results.list)
                    this.page += 1;
                    this.loading = false;
                })
                .catch(() => this.loading = false)
        },
        selectItem(item) {
            this.inputValue = item[this.searchValueName];
            this.selectedItem = item;
            this.open = false;
            this.$emit('onItemSelect', item)
        }
    },
    mounted() {
        const that = this;
        this.$refs.dropdownListContainer.addEventListener('scroll', function(event)
        {
            var element = event.target;
            if (element.scrollHeight - element.scrollTop - 10 < element.clientHeight)
            {
                that.getList();
            }
        });
    }
}
</script>
<style scoped lang="scss">
@import '~bootstrap/scss/bootstrap';
.form-select {
    &:focus {
        &.valid {
            border-color: lighten($success, 20);
            outline: 0;
            box-shadow: 0 0 0 0.25rem rgba(lighten($success, 20), .25);
        }
    }
}
.dropdown-list-container {
    @extend .bg-white;
    @extend .w-100;
    @extend .border;
    @extend .shadow-lg;
    @extend .d-none;
    @extend .overflow-auto;
    @extend .position-absolute;
    z-index: 1;
    max-height: 400px;
    &.active {
        @extend .d-block;
    }
    .dropdown-list-item {
        cursor: pointer;
        padding: 0.375em 0.75em;
        &:hover {
            background-color: $primary;
            color: $white;
        }
    }
    .loading-list-item {
        @extend .text-center;
        height: 40px;
        padding: 0.375em 0.75em;
        svg {
            color: lighten($primary, 20);
            animation: spin 1s linear infinite;
            height: .8em;
        }
        @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
        }
        
    }
}
</style>